import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useRef, useState } from 'react';

const defaultRegionCodes = [514, 438, 263, 450, 579, 354, 819, 873, 468, 418, 581, 367];

const defaultDomains = [
    'gmail.com',
    'hotmail.com',
    'yahoo.com',
    'me.com',
    'aol.com',
    'mac.com',
    'live.com',
    'googlemail.com',
    'msn.com',
    'facebook.com',
    'verizon.net',
    'outlook.com',
    'icloud.com',
    'table.co',
    'fb.com',
    'hotmail.fr',
    'radio-canada.ca',
    'umontreal.ca',
    'urbania.ca',
];

export default function useEmailPhoneAutocomplete({
    domains = [],
    value: initialValue = '',
    defaultType = 'email',
    type: forcedType = null,
    onChange: customOnChange = null,
    onTypeChange = null,
    autocomplete = true,
}) {
    const finalDomains = [...(domains || []), ...defaultDomains];
    const prevVal = useRef('');
    const input = useRef(null);

    const matchPhone = useCallback((value) => {
        const [region = null] = (value || '').split('-');

        if (region !== null) {
            if (region.match(/[a-z@]/) !== null) {
                return null;
            }

            const phoneRegionPattern = new RegExp(
                `^(${defaultRegionCodes.reduce(
                    (line, it, i) => `${line}${it}${i < defaultRegionCodes.length - 1 ? '|' : ''}`,
                    '',
                )})`,
                'i',
            );
            const matchesRegion = region.match(phoneRegionPattern) || null;
            if (matchesRegion !== null && matchesRegion.length > 0) {
                return matchesRegion[0];
            }
            if (region.match(/^[0-9+]{1}/) !== null) {
                return true;
            }
        }
        return null;
    }, []);

    const suggestDomain = useCallback((value) => {
        const [, partialDomain = null] = (value || '').split('@');
        if (partialDomain === null || value.length <= prevVal.current.length) return null;
        const domain =
            finalDomains.find((d) => d.toLowerCase().indexOf(partialDomain.toLowerCase()) === 0) ||
            null;
        const pattern = new RegExp(`^${partialDomain}`, 'i');
        const newValue = domain !== null ? domain.replace(pattern, '') : null;
        return !isEmpty(newValue) ? newValue : null;
    }, []);

    const suggest = useCallback(
        (currentValue) => {
            const phoneMatch = matchPhone(currentValue);
            if (phoneMatch !== null) {
                return ['tel'];
            }
            const domainSuggestion =
                forcedType === null || forcedType === 'email' ? suggestDomain(currentValue) : null;
            if (domainSuggestion !== null) {
                return ['email', domainSuggestion];
            }

            if (currentValue !== null && currentValue.match(/[a-z@]/) !== null) {
                return ['email'];
            }
            return null;
        },
        [finalDomains, matchPhone, suggestDomain, forcedType],
    );

    const [customValue, setValue] = useState(initialValue);
    const [suggestion, setSuggestion] = useState(null);
    const [placeholder, setPlaceholder] = useState(null);
    const [detectedType = null] = suggest(initialValue) || [];
    const [type, setType] = useState(forcedType || detectedType || defaultType);

    const onChange = useCallback(
        (value = '') => {
            const [newDetectedType = null, newSuggestion = null] = suggest(value) || [];
            const newType = forcedType || newDetectedType || defaultType;
            prevVal.current = value;
            setType(newType);
            const hasSuggestion = !isEmpty(newSuggestion) && autocomplete;
            setSuggestion(hasSuggestion ? newSuggestion : null);
            const newValue = hasSuggestion ? `${value}${newSuggestion}` : value;
            setValue(newValue);
            if (customOnChange !== null) {
                customOnChange(newValue, newType);
            }
        },
        [
            suggest,
            forcedType,
            defaultType,
            setSuggestion,
            setValue,
            setType,
            autocomplete,
            customOnChange,
        ],
    );

    useEffect(() => {
        if (suggestion !== null) {
            const newValue = prevVal.current + suggestion;
            const startPos = newValue.lastIndexOf(suggestion);
            const endPos = startPos + suggestion.length;
            input.current.focus();
            const { type: currentInputType } = input.current;
            input.current.type = 'text';
            input.current.setSelectionRange(startPos, endPos);
            input.current.type = currentInputType;
        }
    }, [suggestion]);

    const clear = useCallback(() => setValue(''), [setValue]);

    useEffect(() => {
        if (onTypeChange !== null) {
            onTypeChange(type);
        }
    }, [type, onTypeChange]);

    return {
        type,
        suggestion: customValue,
        placeholder,
        setPlaceholder,
        value: customValue,
        setValue,
        onChange,
        ref: input,
        clear,
    };
}
