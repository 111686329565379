import { useUrlGenerator } from '@folklore/routes';
import { getComponentFromName } from '@folklore/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

// import * as BrandsSponsors from '../../assets/img/sponsors/header';
import PillsMenu from '../menus/PillsMenu';

import styles from '../../styles/headers/brand-header.module.css';

import urbaniaHome from '../../assets/img/brands/urbania_home.png';
import urbaniaLogo from '../../assets/img/brands/urbania_logo.svg';
// import { ReactComponent as Dehors } from '../../assets/img/sponsors/dehors_header_sponsor.svg';
// export { ReactComponent as Mollo } from './mollo_menu_sponsor.svg';
import { ReactComponent as Quatre95 } from '../../assets/img/sponsors/quatre95_menu_sponsor.svg';

const BrandsSponsors = {
    // Dehors,
    Quatre95,
};

const propTypes = {
    handle: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    sections: AppPropTypes.taxonomies,
    section: AppPropTypes.taxonomy,
    images: PropTypes.objectOf(AppPropTypes.image),
    className: PropTypes.string,
};

const defaultProps = {
    sections: null,
    section: null,
    images: {},
    className: null,
};

function BrandHeader({ handle, label, slug, images, sections, section, className }) {
    const url = useUrlGenerator();
    const { logo, logoVertical = null } = images || {};
    const { id: sectionId = null } = section || {};
    const { url: logoUrl = null } = logo || {};
    const { url: logoVerticalUrl = null } = logoVertical || {};
    const SponsorComponent = getComponentFromName(BrandsSponsors, handle);
    const withUrbaniaLogo = handle !== 'urbania' && handle !== 'france' && handle !== 'quatre95';
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hasVerticalLogo]: logoVerticalUrl !== null,
                    [styles.withUrbania]: withUrbaniaLogo,
                },
                styles[handle],
                className,
            ])}
        >
            {withUrbaniaLogo ? (
                <Link href="/" className={styles.urbania}>
                    <img src={urbaniaLogo} className={styles.urbaniaLogo} alt="URBANIA" />
                </Link>
            ) : null}
            <div className={styles.inner}>
                <div className={styles.logoContainer}>
                    <img
                        src={handle === 'urbania' ? urbaniaHome : logoUrl}
                        alt={label}
                        className={styles.logo}
                    />
                    {logoVerticalUrl !== null ? (
                        <img
                            src={logoVerticalUrl}
                            alt={label}
                            className={classNames([styles.logo, styles.logoVertical])}
                        />
                    ) : null}
                </div>
                {SponsorComponent !== null ? (
                    <div className={styles.sponsorContainer}>
                        <SponsorComponent fill="currentColor" className={styles.sponsor} />
                    </div>
                ) : null}
                <div className={styles.menuContainer}>
                    <PillsMenu
                        items={(sections || []).map(
                            ({ id, label: sectionLabel, slug: sectionSlug }) => ({
                                id,
                                label: sectionLabel,
                                href: url('section', {
                                    brand: slug,
                                    section: sectionSlug,
                                }),
                                active: sectionId === id,
                            }),
                        )}
                        className={styles.sectionsMenu}
                        itemsClassName={styles.sections}
                        itemClassName={styles.section}
                        activeClassName={styles.active}
                        buttonClassName={styles.pill}
                    />
                </div>
            </div>
        </div>
    );
}

BrandHeader.propTypes = propTypes;
BrandHeader.defaultProps = defaultProps;

export default BrandHeader;
