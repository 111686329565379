/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useFormat from '../../hooks/useFormat';
import useScrollEnter from '../../hooks/useScrollEnter';

import { TaxonomyAdsTargetingProvider } from '../../contexts/AdsTargeting';
import { useGlobalBrand } from '../../contexts/LayoutContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import SortMenu from '../menus/SortMenu';

import styles from '../../styles/pages/format-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function FormatPage({ slug, current, className, contentClassName, onEnter, onLeave }) {
    const globalBrand = useGlobalBrand();
    const url = useUrlGenerator();
    const { format = null } = useFormat(slug, {
        suspense: false,
    });
    const { id: formatId = null, label, brand = null } = format || {};
    const finalBrand = brand || globalBrand;
    const { handle: brandHandle = null } = finalBrand;
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current && format !== null) {
            tracking.trackPageView({
                brand,
                categories: [format],
                pageType: 'format',
            });
        }
    }, [tracking, current, format, brand, filters]);

    return (
        <TaxonomyAdsTargetingProvider taxonomy={format}>
            <div
                className={classNames([
                    styles.container,
                    styles[brandHandle],
                    className,
                    contentClassName,
                ])}
            >
                <IndexDefaultHeader
                    brand={finalBrand}
                    title={label}
                    placeholder={format === null}
                    className={styles.header}
                />
                <SortMenu
                    url={url('format', {
                        slug,
                    })}
                    className={styles.filters}
                />
                <main className={styles.content}>
                    <InfiniteItemsBlocks
                        query={{
                            format: formatId,
                            ...filters,
                        }}
                        disabled={formatId === null}
                    />
                </main>
            </div>
        </TaxonomyAdsTargetingProvider>
    );
}

FormatPage.propTypes = propTypes;
FormatPage.defaultProps = defaultProps;

export default FormatPage;
