/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import useElementSize from '../../hooks/useElementSize';
import * as AppPropTypes from '../../lib/PropTypes';

import BasicButton from '../buttons/BasicButton';

// import * as AppPropTypes from '../../lib/PropTypes';
import styles from '../../styles/cards/horizontal-card.module.css';

const propTypes = {
    href: PropTypes.string,
    thumbnail: PropTypes.node,
    thumbnailRatio: PropTypes.number,
    maxThumbnailWidthRatio: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    children: PropTypes.node,
    inner: PropTypes.node,
    isLabel: PropTypes.bool,
    labelFor: PropTypes.string,
    containerRef: AppPropTypes.ref,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    href: null,
    thumbnail: null,
    thumbnailRatio: 4 / 3,
    maxThumbnailWidthRatio: 0.3,
    height: null,
    responsive: false,
    children: null,
    inner: null,
    isLabel: false,
    labelFor: null,
    containerRef: null,
    className: null,
    innerClassName: null,
    thumbnailClassName: null,
    labelClassName: null,
    onClick: null,
};

function HorizontalCard({
    href,
    thumbnail,
    thumbnailRatio,
    maxThumbnailWidthRatio,
    height,
    responsive,
    children,
    inner,
    isLabel,
    labelFor,
    containerRef,
    className,
    innerClassName,
    thumbnailClassName,
    labelClassName,
    onClick,
}) {
    const {
        ref,
        height: elementHeight = 0,
        width: elementWidth = 0,
    } = useElementSize({
        disabled: !responsive,
    });

    let thumbnailWidth = null;
    if (responsive) {
        thumbnailWidth = Math.min(
            elementHeight * thumbnailRatio,
            elementWidth * maxThumbnailWidthRatio,
        );
    } else if (thumbnailRatio !== null && height !== null) {
        thumbnailWidth = height * thumbnailRatio;
    }

    const thumbnailHeight = responsive ? '100%' : height;

    const thumbnailStyle = {
        minWidth: thumbnailWidth,
        width: thumbnailWidth,
        minHeight: thumbnailHeight,
        height: responsive ? thumbnailHeight : '100%',
    };

    const labelStyle = {
        marginLeft: responsive ? thumbnailWidth : null,
        // minHeight: thumbnailHeight,
        minHeight: '100%',
    };

    const Element = href !== null || onClick !== null ? 'span' : 'div';

    const cardInner = (
        <Element className={classNames([styles.inner, innerClassName])} ref={ref}>
            {thumbnail !== null ? (
                <Element
                    className={classNames([
                        styles.thumbnail,
                        {
                            [thumbnailClassName]: thumbnailClassName !== null,
                        },
                    ])}
                    style={thumbnailStyle}
                >
                    {thumbnail}
                </Element>
            ) : null}
            <Element
                className={classNames([
                    styles.label,
                    {
                        [labelClassName]: labelClassName !== null,
                    },
                ])}
                style={labelStyle}
            >
                {children}
            </Element>
            {inner}
        </Element>
    );

    if (isLabel) {
        return (
            <label
                className={classNames([
                    styles.container,
                    {
                        [styles.responsive]: responsive,
                        [className]: className !== null,
                    },
                ])}
                htmlFor={labelFor}
                ref={containerRef}
            >
                {cardInner}
            </label>
        );
    }

    if (href !== null) {
        return (
            <Link
                href={href}
                onClick={onClick}
                className={classNames([
                    styles.container,
                    {
                        [styles.responsive]: responsive,
                        [className]: className !== null,
                    },
                ])}
                ref={containerRef}
            >
                {cardInner}
            </Link>
        );
    }
    if (onClick !== null) {
        return (
            <BasicButton
                onClick={onClick}
                className={classNames([
                    styles.container,
                    {
                        [styles.responsive]: responsive,
                        [className]: className !== null,
                    },
                ])}
            >
                {cardInner}
            </BasicButton>
        );
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.responsive]: responsive,
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            {cardInner}
        </div>
    );
}

HorizontalCard.propTypes = propTypes;
HorizontalCard.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <HorizontalCard {...props} containerRef={ref} />);
