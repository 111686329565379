import seedrandom from 'seedrandom';

export default function getPlaceholderText(
    linesCount = 1,
    { characters, minCharacters = 6, maxCharacters = 10 } = {},
) {
    const rand = seedrandom(`${minCharacters}-${maxCharacters}`);
    const lines = [...Array(linesCount).keys()].map(() => ({
        charactersCount:
            characters || minCharacters + Math.round(rand() * (maxCharacters - minCharacters)),
    }));
    return lines
        .map(({ charactersCount }) => [...Array(charactersCount).keys()].map(() => '█').join(''))
        .join('\n');
}
