/* eslint-disable react/no-array-index-key */

/* eslint-disable react/no-danger */
import { useRoutes } from '@folklore/routes';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'wouter';

import useWindowSize from '../hooks/useWindowSize';

import { useBrands } from '../contexts/SiteContext';
import MetadataRoutes from './MetadataRoutes';

const propTypes = {
    assetsManifest: PropTypes.shape({
        files: PropTypes.objectOf(PropTypes.string).isRequired,
        entrypoints: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    embeddedStyles: PropTypes.string,
    embeddedScripts: PropTypes.string,
    tagManagerId: PropTypes.string,
    facebookAppId: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    embeddedStyles: null,
    embeddedScripts: null,
    tagManagerId: 'GTM-WBRBHVQ',
    facebookAppId: null,
};

function Html({
    assetsManifest,
    embeddedStyles,
    embeddedScripts,
    tagManagerId,
    facebookAppId,
    children,
}) {
    const { locale } = useIntl();
    const { files: assetsFiles, entrypoints = [] } = assetsManifest;
    const routes = useRoutes();
    const brands = useBrands();
    const { width: windowWidth = null, height: windowHeight = null } = useWindowSize();
    const hasSize =
        windowWidth !== null && windowWidth > 0 && windowHeight !== null && windowHeight > 0;

    return (
        <html lang={locale}>
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="fb:app_id" content={facebookAppId} />
                <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />
                <MetadataRoutes />
                <Route path={routes.home}>
                    {({ brand: brandSlug = null }) => {
                        const brand = brands.find(
                            ({ slug, isDefault = false }) =>
                                slug === brandSlug || (brandSlug === null && isDefault),
                        );
                        const { videos = null } = brand;
                        const mode = windowWidth > windowHeight ? 'horizontal' : 'vertical';
                        const video =
                            hasSize && videos != null ? videos[camelCase(`bumper_${mode}`)] : null;
                        return (video !== null ? video.sources : [])
                            .filter(({ mime = '' }) => mime.match(/^video\//) !== null)
                            .map(({ url, mime }) => <link rel="preload" href={url} type={mime} />);
                    }}
                </Route>
                <link
                    rel="preload"
                    href="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                    as="script"
                />
                <link
                    rel="preload"
                    href={assetsFiles['static/media/Agrandir-WideBold.woff2']}
                    as="font"
                />
                <link
                    rel="preload"
                    href={assetsFiles['static/media/Agrandir-TightBold.woff2']}
                    as="font"
                />
                <link
                    rel="preload"
                    href={assetsFiles['static/media/Agrandir-TightMedium.woff2']}
                    as="font"
                />
                <link
                    rel="preload"
                    href={assetsFiles['static/media/GarageGothic.woff2']}
                    as="font"
                />
                {embeddedStyles === null ? (
                    entrypoints
                        .filter((it) => it.match(/\.css/) !== null)
                        .map((file) => (
                            <link
                                rel="preload"
                                href={`/${file}`}
                                key={`preload-${file}`}
                                as="style"
                            />
                        ))
                ) : (
                    <style
                        type="text/css"
                        id="embedded-styles"
                        dangerouslySetInnerHTML={{ __html: embeddedStyles }}
                    />
                )}
                <link rel="preconnect" href="https://v2.urbania.ca" />
                {embeddedScripts !== null ? (
                    <script
                        type="text/javascript"
                        id="embedded-styles"
                        dangerouslySetInnerHTML={{
                            __html: embeddedScripts,
                        }}
                    />
                ) : null}
            </head>
            <body>
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${tagManagerId}`}
                        title="GTM"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    />
                </noscript>
                {children}
                {embeddedStyles === null
                    ? entrypoints
                          .filter((it) => it.match(/\.css/) !== null)
                          .map((file) => (
                              <link rel="stylesheet" href={`/${file}`} key={`stylesheet-${file}`} />
                          ))
                    : null}
            </body>
        </html>
    );
}

Html.propTypes = propTypes;
Html.defaultProps = defaultProps;

export default Html;
