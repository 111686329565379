/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import SortMenu from '../menus/SortMenu';

import styles from '../../styles/pages/section-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function SectionPage({ brandSlug, slug, current, className, contentClassName, onEnter, onLeave }) {
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null, sections = [] } = brand || {};
    const section = sections.find(({ slug: sectionSlug }) => sectionSlug === slug) || null;

    const url = useUrlGenerator();
    const { id: sectionId = null, label } = section || {};
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                section,
                pageType: 'section',
            });
        }
    }, [tracking, current, brand, section, filters]);

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
            ])}
        >
            <IndexDefaultHeader brand={brand} title={label} className={styles.header} />
            <SortMenu
                url={url('section', {
                    brand: brandSlug,
                    section: slug,
                })}
                className={styles.filters}
            />
            <main className={styles.content}>
                {sectionId !== null ? (
                    <InfiniteItemsBlocks
                        query={{
                            section: sectionId,
                            ...filters,
                        }}
                        cardWithout={['category']}
                    />
                ) : null}
            </main>
        </div>
    );
}

SectionPage.propTypes = propTypes;
SectionPage.defaultProps = defaultProps;

export default SectionPage;
