/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useCollection from '../../hooks/useCollection';
import useScrollEnter from '../../hooks/useScrollEnter';
import { useCollectionSurtitle } from '../../hooks/useSurtitle';
import { getCollectionBackground } from '../../lib/backgrounds';

import { CollectionAdsTargetingProvider } from '../../contexts/AdsTargeting';
import Blocks from '../blocks/Blocks';
import CollectionDefaultHeader from '../headers/CollectionDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import PageSectionTitle from '../typography/PageSectionTitle';
import NotFoundPage from './NotFoundPage';

import styles from '../../styles/pages/collection-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function CollectionPage({ slug, current, className, contentClassName, onEnter, onLeave }) {
    const intl = useIntl();
    const { collection = null, notFound = false } = useCollection(slug);
    const {
        type = null,
        id: collectionId = null,
        surtitle,
        title,
        description,
        header = null,
        metadata,
        image,
        seasons = null,
    } = collection || {};
    const { brands, sponsors } = metadata || {};

    const finalSurtitle = useCollectionSurtitle({ type, surtitle });

    const { ref: endRef, visible: endVisible = false } = useIsVisible({
        disabled: !current,
        // rootMargin: `100px 0px`,
    });

    useScrollEnter({
        leave: endVisible,
        disabled: !current,
        onEnter,
        onLeave,
    });

    const isPodcast = type === 'serie_podcast' || type === 'season_podcast';
    const hasSeasons = seasons !== null && seasons.length > 0;

    const tracking = useTracking();
    useEffect(() => {
        if (current && collection !== null) {
            tracking.trackPageViewFromDocument(collection);
        }
    }, [tracking, current, collection]);

    if (notFound) {
        return <NotFoundPage current={current} />;
    }

    return (
        <CollectionAdsTargetingProvider collection={collection}>
            <div className={classNames([styles.container, className, contentClassName])}>
                <CollectionDefaultHeader
                    type={type}
                    title={title}
                    surtitle={finalSurtitle}
                    description={description}
                    brands={brands}
                    sponsors={sponsors}
                    {...header}
                    image={header !== null ? header.image || image : image}
                    placeholder={collection === null}
                    className={styles.header}
                />
                <main className={styles.content}>
                    {hasSeasons ? (
                        <Blocks
                            blocks={seasons.map(({ id: seasonId, title: seasonTitle }) => ({
                                type: 'documents-row',
                                presentation: 'grid',
                                title: <PageSectionTitle>{seasonTitle}</PageSectionTitle>,

                                cardWithout: ['brand-icon', 'serie', 'collection', 'category'],
                                query: {
                                    collection: seasonId,
                                },
                                loading: 'lazy',

                                count: null,
                                placeholderCount: 4,
                                ...(isPodcast
                                    ? {
                                          cardType: 'horizontal',
                                          cardTheme: 'box-translucent-dark',
                                          cardRatio: 'square',
                                          columns: 1,
                                      }
                                    : {
                                          cardType: 'over',
                                          cardSize: 'big',
                                          cardTheme: 'outline',
                                          cardRatio: 'vertical',
                                          cardWidth: 300,
                                      }),
                            }))}
                            className={styles.blocks}
                            blockClassName={classNames([
                                styles.season,
                                {
                                    [styles.podcast]: isPodcast,
                                },
                            ])}
                        />
                    ) : null}
                    {hasSeasons && isPodcast ? (
                        <PageSectionTitle className={styles.sectionTitle}>
                            Contenus reliés
                        </PageSectionTitle>
                    ) : null}
                    <InfiniteItemsBlocks
                        query={
                            hasSeasons && isPodcast
                                ? {
                                      collection: collectionId,
                                      exclude_type: 'podcast',
                                  }
                                : {
                                      collection: collectionId,
                                  }
                        }
                        disabled={collectionId === null}
                        className={styles.blocks}
                        cardTheme="box-translucent-dark"
                        cardWithout={['brand-icon', 'serie', 'collection']}
                    />
                </main>
                <div
                    ref={endRef}
                    style={{
                        height: 1,
                    }}
                />
            </div>
        </CollectionAdsTargetingProvider>
    );
}

CollectionPage.propTypes = propTypes;
CollectionPage.defaultProps = defaultProps;

export default CollectionPage;
