/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// import useBackgroundIsDark from '../../hooks/useBackgroundIsDark';
import * as AppPropTypes from '../../lib/PropTypes';

import PillButton from '../buttons/PillButton';
import Background from '../partials/Background';
import Image from '../partials/Image';
import PlaceholderText from '../partials/PlaceholderText';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import CollectionHeaderDescription from '../typography/CollectionHeaderDescription';
import HeaderDescription from '../typography/HeaderDescription';
import HeaderTitle from '../typography/HeaderTitle';
import IndexDefaultHeader from './IndexDefaultHeader';

import styles from '../../styles/headers/collection-default-header.module.css';

const propTypes = {
    type: PropTypes.string,
    brands: AppPropTypes.taxonomies,
    sponsors: AppPropTypes.sponsors,
    title: PropTypes.string,
    surtitle: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    background: AppPropTypes.background,
    cta: AppPropTypes.callToAction,
    color: PropTypes.string,
    image: AppPropTypes.image,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: null,
    brands: null,
    sponsors: null,
    title: null,
    surtitle: null,
    background: null,
    description: null,
    subtitle: null,
    image: null,
    color: '#fff',
    cta: null,
    placeholder: false,
    className: null,
};

function CollectionDefaultHeader({
    type,
    brands,
    title,
    surtitle,
    subtitle,
    description,
    image,
    color,
    cta,
    sponsors,
    placeholder,
    background,
    className,
}) {
    const [sponsor = null] = sponsors || [];
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};

    const { image: backgroundImage = null } = background || {};
    const { width: imageWidth = 0, height: imageHeight = 0 } = backgroundImage || {};

    const { label: ctaLabel = null, url: ctaUrl = null } = cta || {};

    const hasOnlyTitle = description === null && background === null && image === null;
    if (hasOnlyTitle && !placeholder) {
        return <IndexDefaultHeader brand={brand} title={title} className={className} />;
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.imageOnly]: background === null && image !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.imagesContainer}>
                    {background !== null ? (
                        <div className={styles.backgroundContainer}>
                            <Background
                                {...background}
                                className={styles.background}
                                imageClassName={styles.image}
                                pictureClassName={styles.picture}
                            />
                        </div>
                    ) : null}

                    {background === null && image !== null ? (
                        <div className={styles.backgroundContainer}>
                            <Image
                                className={styles.image}
                                media={image}
                                size="responsive"
                                pictureClassName={styles.picture}
                            />
                        </div>
                    ) : null}

                    <div className={styles.imageContainer}>
                        {image !== null && background !== null ? (
                            <Image
                                className={styles.image}
                                media={image}
                                size="responsive"
                                pictureClassName={styles.picture}
                            />
                        ) : null}
                    </div>
                </div>

                <div className={styles.boxContainer}>
                    <div className={styles.box}>
                        {surtitle !== null ? (
                            <Surtitle surtitle={surtitle} className={styles.surtitle} />
                        ) : null}
                        <HeaderTitle brand={brandHandle} className={styles.title}>
                            {placeholder ? <PlaceholderText lines={2} /> : title}
                        </HeaderTitle>
                        {subtitle !== null ? (
                            <HeaderDescription className={styles.subtitle}>
                                {subtitle}
                            </HeaderDescription>
                        ) : null}
                        {sponsor !== null ? (
                            <Sponsor
                                {...sponsor}
                                withImage
                                className={styles.sponsor}
                                imageClassName={styles.sponsorImage}
                            />
                        ) : null}
                        {placeholder ? (
                            <CollectionHeaderDescription className={styles.description}>
                                <PlaceholderText lines={4} />
                            </CollectionHeaderDescription>
                        ) : null}
                        {description !== null && !placeholder ? (
                            <CollectionHeaderDescription
                                className={styles.description}
                                html={description}
                            />
                        ) : null}
                        {cta !== null ? (
                            <PillButton href={ctaUrl} className={styles.button} compact>
                                {ctaLabel || (
                                    <FormattedMessage
                                        defaultMessage="En savoir plus..."
                                        description="Button label"
                                    />
                                )}
                            </PillButton>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

CollectionDefaultHeader.propTypes = propTypes;
CollectionDefaultHeader.defaultProps = defaultProps;

export default CollectionDefaultHeader;
