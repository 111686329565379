/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import MicromagSummary from '../summaries/MicromagSummary';
import DocumentFeaturedCard from './DocumentFeaturedCard';

import styles from '../../styles/cards/micromag-featured-card.module.css';

const propTypes = {
    slug: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    slug: null,
    className: null,
};

function MicromagFeaturedCard({ slug, className, ...props }) {
    const url = useUrlGenerator();
    return (
        <DocumentFeaturedCard
            {...props}
            slug={slug}
            className={classNames(styles.container, className)}
            label={
                <MicromagSummary
                    link={slug !== null ? url('micromag', { slug }) : null}
                    className={styles.summary}
                    titleClassName={styles.summaryTitle}
                    descriptionClassName={styles.summaryDescription}
                />
            }
        />
    );
}

MicromagFeaturedCard.propTypes = propTypes;
MicromagFeaturedCard.defaultProps = defaultProps;

export default MicromagFeaturedCard;
