/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import PillButton from '../buttons/PillButton';
import MenuLabel from '../typography/MenuLabel';

import styles from '../../styles/menus/pills-menu.module.css';

const propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(AppPropTypes.menuItem),
    titleClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    activeClassName: PropTypes.bool,
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    items: [],
    titleClassName: null,
    itemsClassName: null,
    itemClassName: null,
    activeClassName: null,
    buttonClassName: null,
    className: null,
};

function PillsMenu({
    title,
    items,
    titleClassName,
    itemsClassName,
    itemClassName,
    activeClassName,
    buttonClassName,
    className,
}) {
    return (
        <nav className={classNames([styles.container, className])}>
            {title !== null ? (
                <MenuLabel tag="span" className={classNames([styles.title, titleClassName])}>
                    {title}
                </MenuLabel>
            ) : null}
            <ul className={classNames([styles.items, itemsClassName])}>
                {(items || []).map(
                    ({
                        id = null,
                        label: itemLabel = null,
                        href = null,
                        external = false,
                        active = false,
                        buttonClassName: customButtonClassName = null,
                        activeClassName: customActiveClassName = null,
                        ...props
                    }) => (
                        <li key={id} className={classNames([styles.item, itemClassName])}>
                            <PillButton
                                compact
                                {...props}
                                href={href}
                                external={external}
                                dark={active}
                                className={classNames([
                                    styles.button,
                                    buttonClassName,
                                    customButtonClassName,
                                ])}
                                darkClassName={classNames([activeClassName, customActiveClassName])}
                            >
                                {itemLabel}
                            </PillButton>
                        </li>
                    ),
                )}
            </ul>
        </nav>
    );
}

PillsMenu.propTypes = propTypes;
PillsMenu.defaultProps = defaultProps;

export default PillsMenu;
