import React from 'react';
import { useParams } from 'wouter';

import useDocument, { useDocumentPreview } from '../../hooks/useDocument';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';

import Metadata from './Metadata';
import NotfoundMetadata from './NotfoundMetadata';

const propTypes = {};

const defaultProps = {};

function DocumentMetadata() {
    const { slug: slugParam, previewToken = null } = useParams();
    const { document = null, notFound = false } =
        previewToken !== null ? useDocumentPreview(previewToken) : useDocument(slugParam);
    const url = useDocumentUrlGenerator();
    if (notFound) {
        return <NotfoundMetadata />;
    }
    if (document === null) {
        return null;
    }
    const { type, title, slug, metadata, image } = document;
    const { description, brands, keywords } = metadata || {};
    const [brand] = brands || [];
    return (
        <Metadata
            brand={brand}
            title={title}
            description={description}
            keywords={keywords}
            image={image}
            url={slug !== null ? url({
                type,
                slug,
            }) : null}
        />
    );
}

DocumentMetadata.propTypes = propTypes;
DocumentMetadata.defaultProps = defaultProps;

export default DocumentMetadata;
