/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useIsCheckingAuth } from '../../hooks/useAuth';
import { useSubscribe } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSite } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import FormButton from '../buttons/FormButton';
import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import EmailPhoneField from '../fields/EmailPhoneField';
import FieldArrow from '../icons/FieldArrowIcon';
import LoadingIcon from '../icons/LoadingIcon';
import FormStatus from '../partials/FormStatus';
import EditContact from './EditContactForm';
import FormControl from './FormControl';

import styles from '../../styles/forms/subscription-form.module.css';

const propTypes = {
    subscription: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    source: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    full: PropTypes.bool,
    withoutClose: PropTypes.bool,
    withoutCancel: PropTypes.bool,
    withoutButtons: PropTypes.bool,
    withoutSubscriptions: PropTypes.bool,
    withSubscribeButton: PropTypes.bool,
    fieldRef: AppPropTypes.ref,
    inputRef: AppPropTypes.ref,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onComplete: PropTypes.func,
    onCancel: PropTypes.func,
    onFieldPointerUp: PropTypes.func,
    className: PropTypes.string,
    fieldsClassName: PropTypes.string,
    buttonsClassName: PropTypes.string,
    subscriptionsClassName: PropTypes.string,
};

const defaultProps = {
    placeholder: null,
    source: null,
    label: null,
    full: false,
    withoutClose: false,
    withoutCancel: false,
    withoutButtons: false,
    withoutSubscriptions: false,
    withSubscribeButton: false,
    fieldRef: null,
    inputRef: null,
    onFocus: null,
    onBlur: null,
    onComplete: null,
    onCancel: null,
    onFieldPointerUp: null,
    className: null,
    fieldsClassName: null,
    buttonsClassName: null,
    subscriptionsClassName: null,
};

function SubscriptionForm({
    subscription,
    source,
    placeholder,
    label,
    full,
    withoutClose,
    withoutCancel,
    withoutButtons,
    withoutSubscriptions,
    withSubscribeButton,
    fieldRef,
    inputRef,
    onFocus,
    onBlur,
    onComplete,
    onCancel,
    onFieldPointerUp,
    className,
    fieldsClassName,
    buttonsClassName,
    subscriptionsClassName,
}) {
    const intl = useIntl();
    const url = useUrlGenerator();
    const { subscribeAsync } = useSubscribe();
    const formFields = useMemo(() => ['field', 'phone', 'email'].filter(Boolean), []);

    const [fieldType, setFieldType] = useState('email');
    const onFieldTypeChange = useCallback((newType) => setFieldType(newType), [setFieldType]);

    const [subscriptions, setSubscriptions] = useState(subscription !== null ? [subscription] : []);
    const user = useUser();
    const { subscriptions: userSubscriptions = [] } = user || {};
    const initialSubscribed =
        userSubscriptions.findIndex(
            ({ id, subscribed = false }) => id === subscription && subscribed,
        ) !== -1;
    const [completed, setCompleted] = useState(initialSubscribed);

    const postSendToken = useCallback(
        (formAction, { field }) =>
            subscribeAsync(
                full
                    ? {
                          subscriptions,
                          source,
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      }
                    : {
                          subscription,
                          source,
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      },
            ),
        [fieldType, subscribeAsync, source, subscription, subscriptions],
    );
    const tracking = useTracking();
    const onFormComplete = useCallback(() => {
        setCompleted(true);
        if (onComplete !== null) {
            onComplete();
        }
        tracking.trackSubscribe(subscription, source);
    }, [onComplete, tracking, source, subscription]);

    const { fields, onSubmit, status, setValue } = useForm({
        fields: formFields,
        postForm: postSendToken,
        onComplete: onFormComplete,
    });

    const { errors } = fields[fieldType === 'tel' ? 'phone' : 'email'] || {};

    const { subscriptions: allSubscriptions } = useSite();
    const availableSubscriptions = useMemo(
        () =>
            allSubscriptions
                .filter(({ handle, type }) => type !== 'notification' || handle === subscription)
                .filter(
                    ({ handle }) =>
                        userSubscriptions.findIndex(
                            ({ id, subscribed }) => id === handle && subscribed,
                        ) === -1 ||
                        handle === subscription ||
                        completed,
                )
                .sort(({ handle: aHandle }, { handle: bHandle }) => {
                    if (aHandle === subscription) {
                        return -1;
                    }
                    if (bHandle === subscription) {
                        return 1;
                    }
                    return 0;
                }),
        [allSubscriptions, subscription],
    );

    const onSubscriptionChange = useCallback(
        ({ handle }, checked) => {
            setSubscriptions(
                checked ? [...subscriptions, handle] : subscriptions.filter((s) => s !== handle),
            );
        },
        [setSubscriptions, subscriptions],
    );

    const onEditContact = useCallback(() => {
        if (!full && onFocus !== null) {
            onFocus();
            return false;
        }
        return true;
    }, [full, onFocus]);

    const onClickCancel = useCallback(() => {
        setValue(null);
        if (onCancel !== null) {
            onCancel();
        }
    }, [setValue, onCancel]);

    const finalWithSubscribeButton = !full && (withSubscribeButton || user !== null);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                    // [styles.completed]: completed,
                    [styles.full]: full,
                    [styles.withSubscribeButton]: finalWithSubscribeButton,
                    [className]: className !== null,
                },
            ])}
        >
            {user !== null ? (
                <div className={classNames([styles.fields, fieldsClassName])}>
                    <FormControl className={styles.formControl}>
                        <EditContact
                            type={fieldType === 'tel' ? 'phone' : 'email'}
                            label={false}
                            onEdit={onEditContact}
                        />
                    </FormControl>
                </div>
            ) : null}
            <form onSubmit={onSubmit}>
                {user === null ? (
                    <div className={classNames([styles.fields, fieldsClassName])}>
                        <FormControl
                            className={styles.formControl}
                            label={label}
                            {...fields[fieldType === 'tel' ? 'phone' : 'email']}
                        >
                            <div className={styles.fieldGroup} ref={fieldRef}>
                                <EmailPhoneField
                                    {...fields.field}
                                    ref={inputRef}
                                    errors={errors}
                                    className={styles.field}
                                    inputClassName={styles.input}
                                    placeholder={
                                        placeholder ||
                                        intl.formatMessage({
                                            defaultMessage: 'Inscrivez votre n. de tel ou courriel',
                                            description: 'Field placeholder',
                                        })
                                    }
                                    onTypeChange={onFieldTypeChange}
                                    onFocus={onFocus}
                                    onPointerUp={onFieldPointerUp}
                                    onBlur={onBlur}
                                />

                                <BasicButton type="submit" className={styles.arrowButton}>
                                    {status === 'loading' ? (
                                        <LoadingIcon className={styles.icon} />
                                    ) : (
                                        <FieldArrow className={styles.icon} />
                                    )}
                                </BasicButton>
                            </div>
                        </FormControl>
                    </div>
                ) : null}
                {!withoutSubscriptions ? (
                    <div className={classNames([styles.subscriptions, subscriptionsClassName])}>
                        {availableSubscriptions.map((subscriptionItem) => (
                            <SubscriptionHorizontalCard
                                {...subscriptionItem}
                                thumbnailWidth={50}
                                source="account"
                                checked={subscriptions.indexOf(subscriptionItem.handle) !== -1}
                                withCheckbox={!completed}
                                withToggle={completed}
                                className={styles.subscription}
                                onChange={(checked) =>
                                    onSubscriptionChange(subscriptionItem, checked)
                                }
                            />
                        ))}
                    </div>
                ) : null}
                <div className={classNames([styles.buttons, buttonsClassName])}>
                    {!completed && !withoutCancel && !withoutButtons ? (
                        <FormButton
                            transparent
                            className={styles.secondaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Annuler" description="Button label" />
                        </FormButton>
                    ) : null}
                    {completed && !withoutButtons ? (
                        <FormButton
                            href={url('account.subscriptions')}
                            transparent
                            className={styles.linkButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage
                                defaultMessage="Gérer vos abonnements"
                                description="Button label"
                            />
                        </FormButton>
                    ) : null}
                    <FormStatus
                        status={status}
                        successMessage="Merci!"
                        iconRight
                        className={styles.status}
                    />
                    {!completed ? (
                        <FormButton
                            type="submit"
                            dark
                            className={styles.primaryButton}
                            disabled={status === 'loading'}
                        >
                            <FormattedMessage
                                defaultMessage="S'abonner"
                                description="Button label"
                            />
                        </FormButton>
                    ) : null}

                    {completed && !withoutClose && !withoutButtons ? (
                        <FormButton
                            type="button"
                            dark
                            className={styles.primaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Fermer" description="Button label" />
                        </FormButton>
                    ) : null}
                </div>
            </form>
        </div>
    );
}

SubscriptionForm.propTypes = propTypes;
SubscriptionForm.defaultProps = defaultProps;

export default SubscriptionForm;
