/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import Image from '../partials/Image';
import CardTitle from '../typography/CardTitle';
import OverCard from './OverCard';

import styles from '../../styles/cards/brand-over-card.module.css';

const propTypes = {
    handle: PropTypes.string,
    slug: PropTypes.string,
    image: AppPropTypes.image,
    width: PropTypes.number,
    ratio: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    handle: null,
    slug: null,
    image: null,
    width: 320,
    ratio: null,
    description: null,
    className: null,
};

function BrandOverCard({ handle, slug, image, description, width, ratio, className }) {
    const thumbnailRatio = getRatio(ratio, null);

    return (
        <OverCard
            href={slug !== null ? `/${slug}` : '/'}
            thumbnailRatio={thumbnailRatio}
            thumbnail={
                <Image
                    width={width}
                    height={width / thumbnailRatio}
                    media={image}
                    className={styles.thumbnail}
                    pictureClassName={styles.picture}
                    withoutSize
                />
            }
            className={classNames([styles.container, styles[handle], className])}
            labelClassName={styles.label}
        >
            <CardTitle className={styles.description}>{description}</CardTitle>
        </OverCard>
    );
}

BrandOverCard.propTypes = propTypes;
BrandOverCard.defaultProps = defaultProps;

export default BrandOverCard;
