/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import PlaceholderText from '../partials/PlaceholderText';
import IndexTitle from '../typography/IndexTitle';

import styles from '../../styles/headers/index-default-header.module.css';

const propTypes = {
    title: PropTypes.string,
    brand: AppPropTypes.brand,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    brand: null,
    placeholder: false,
    className: null,
};

function IndexDefaultHeader({ title, brand, placeholder, className }) {
    const { handle: brandHandle = null } = brand || {};

    return (
        <div className={classNames([styles.container, styles[brandHandle], className])}>
            <div className={styles.bar}>
                <IndexTitle brand={brand} className={styles.title} placeholder={placeholder}>
                    {title}
                </IndexTitle>
            </div>
        </div>
    );
}

IndexDefaultHeader.propTypes = propTypes;
IndexDefaultHeader.defaultProps = defaultProps;

export default IndexDefaultHeader;
