/* eslint-disable react/jsx-props-no-spreading */
import { animated, useSpring } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import useElementSize from '../../hooks/useElementSize';

import SubscriptionForm from '../forms/SubscriptionForm';
import Popout from '../popups/Popout';

import styles from '../../styles/partials/subscription-wizard.module.css';

const propTypes = {
    subscription: PropTypes.string.isRequired,
    source: PropTypes.string,
    withPopout: PropTypes.bool,
    withFull: PropTypes.bool,
    onComplete: PropTypes.func,
    className: PropTypes.string,
    formClassName: PropTypes.string,
};

const defaultProps = {
    source: null,
    withPopout: false,
    withFull: false,
    onComplete: null,
    className: null,
    formClassName: null,
};

function SubscriptionWizard({
    subscription,
    source,
    withPopout,
    withFull,
    onComplete,
    className,
    formClassName,
}) {
    const [detach, setDetach] = useState(false);
    const [detached, setDetached] = useState(false);
    const inputRef = useRef(null);
    const onFocus = useCallback(() => {
        if (!withPopout) {
            return;
        }
        setTimeout(() => {
            setDetach(true);
            if (!withPopout) {
                setDetached(true);
            }
        }, 100);
    }, [setDetach, withPopout, setDetached]);
    const onCancel = useCallback(() => {
        setDetach(false);
    }, [setDetach]);
    const requestClose = useCallback(() => {
        setDetach(false);
        inputRef.current.blur();
    }, [setDetach]);
    const onDetach = useCallback(() => setDetached(true), [setDetached]);
    const onAttach = useCallback(() => setDetached(false), [setDetached]);
    const { ref: fieldRef, height: fieldHeight = 0 } = useElementSize();
    const formStyle = useSpring({
        maxHeight: detached && detach ? 1000 : fieldHeight,
        immediate: !detached && withPopout,
        onResolve: () => {
            if (!detach && !withPopout) {
                setDetached(false);
            }
        },
    });

    return (
        <Popout
            detach={detach && withPopout}
            initialStyle={{
                borderRadius: 0,
            }}
            attachedStyle={{
                borderRadius: 10,
            }}
            detachedStyle={{
                borderRadius: 0,
            }}
            onDetach={onDetach}
            onAttach={onAttach}
            requestClose={requestClose}
            className={classNames([
                styles.container,
                {
                    [styles.withPopout]: withPopout,
                    [styles.detached]: detached,
                    [styles.detaching]: !detached && detach,
                    [styles.attaching]: detached && !detach,
                },
                className,
            ])}
            modalClassName={styles.modal}
        >
            <animated.div style={withFull ? formStyle : null} className={styles.formContainer}>
                <SubscriptionForm
                    subscription={subscription}
                    source={source}
                    full={withFull && (detached || detach)}
                    fieldRef={fieldRef}
                    inputRef={inputRef}
                    onFocus={onFocus}
                    onCancel={onCancel}
                    onComplete={onComplete}
                    className={classNames([styles.form, formClassName])}
                    fieldsClassName={styles.fields}
                    subscriptionsClassName={styles.subscriptions}
                    buttonsClassName={styles.buttons}
                />
            </animated.div>
        </Popout>
    );
}

SubscriptionWizard.propTypes = propTypes;
SubscriptionWizard.defaultProps = defaultProps;

export default SubscriptionWizard;
