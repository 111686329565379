/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';

import styles from '../../styles/pages/micromags-page.module.css';
import { useTracking } from '@folklore/tracking';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function MicromagsPage({ brandSlug, current, className, contentClassName, onEnter, onLeave }) {
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};
    const filters = useFiltersQuery();

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                pageType: 'index',
            });
        }
    }, [tracking, current, brand, filters]);

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
            ])}
        >
            <IndexDefaultHeader
                brand={brand}
                title={<FormattedMessage defaultMessage="Micromags" description="Page title" />}
                className={styles.header}
            />
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        type: 'micromag',
                        collection: 'micromag-urbania',
                        ...filters,
                    }}
                    // blocks={[
                    //     {
                    //         role: 'block',
                    //         type: 'header_featured_sidebar',
                    //         cardType: 'over',
                    //         cardRatio: 'micromag',
                    //         cardTheme: 'outline',
                    //         withShadow: true,
                    //         responsive: true,
                    //         count: 7,
                    //     },
                    // ]}
                    itemsBlocks={[
                        {
                            type: 'items',
                            itemsType: 'documents',
                            presentation: 'grid',
                            cardType: 'over',
                            cardRatio: 'micromag',
                            cardTheme: 'outline',
                            cardWidth: 200,
                            // cardSize: 'big',
                            cardWithout: ['brand-icon'],
                            minColumns: 2,
                            count: 12,
                        },
                    ]}
                />
            </main>
        </div>
    );
}

MicromagsPage.propTypes = propTypes;
MicromagsPage.defaultProps = defaultProps;

export default MicromagsPage;
