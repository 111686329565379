/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';
import { createLayoutRow } from '../../lib/blocks';

import PageSectionTitle from '../typography/PageSectionTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-france.module.css';
import UrbaniaHeader from '../headers/UrbaniaHeader';
import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import { usePopularDocuments } from '../../hooks/useDocuments';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageFrance({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const blocks = useUniqueDocumentsBlocks([
        {
            role: 'block',
            type: 'header_featured_list',
            brand,
            // withShadow: true,
            // cardType: 'over',
            // cardTheme: 'outline',
            // cardRatio: 'vertical',
            cardTheme: 'over-bottom-left',
            count: 1,
            className: styles.featuredBlock,
            innerClassName: styles.featuredInner,
            cardClassName: styles.featuredCard,
            query: {
                brand: brand.id,
            },
            uniqueDocuments: true,
        },
        createLayoutRow('1_1_4_1', {
            cardTheme: 'box-translucent',
            title: (
                <PageSectionTitle className={styles.sectionTitle}>
                    <Link
                        href={url('articles', {
                            brand: brand.slug,
                        })}
                    >
                        Les plus récents
                    </Link>
                </PageSectionTitle>
            ),
            query: {
                brand: brand.id,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <PageSectionTitle className={styles.sectionTitle}>
                    Les plus populaires
                </PageSectionTitle>
            ),
            query: {
                brand: brand.id,
                // popular: 'month',
            },
            hook: usePopularDocuments,
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        {
            role: 'block',
            type: 'documents-row',
            presentation: 'row',
            title: (
                <PageSectionTitle brand={brand.handle} className={styles.sectionTitle}>
                    <Link href={url('videos', { brand: brand.slug })}>Vidéos récentes</Link>
                </PageSectionTitle>
            ),
            query: {
                type: 'video',
                brand: brand.id,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'over',
            cardSize: 'big',
            cardTheme: 'outline',
            cardRatio: 'vertical',
            cardWidth: 300,
            cardWithout: ['brand-icon'],
            withBorderRadius: false,
        },
    ]);
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            blocks={blocks}
            header={<UrbaniaHeader className={styles.header} />}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageFrance.propTypes = propTypes;
HomePageFrance.defaultProps = defaultProps;

export default HomePageFrance;
