/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUser } from '@folklore/auth';
import { useTracking } from '@folklore/tracking';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscribe, useUnsubscribe } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import RoundedButton from '../buttons/RoundedButton';
import ToggleButton from '../buttons/ToggleButton';
import ViaButton from '../buttons/ViaButton';
import ToggleIcon from '../icons/ToggleIcon';
import AuthorImage from '../partials/AuthorImage';
import Image from '../partials/Image';
import EditContactModal from '../popups/EditContactModal';
import CardDescription from '../typography/CardDescription';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/subscription-horizontal-card.module.css';

const propTypes = {
    handle: PropTypes.string.isRequired,
    author: AppPropTypes.author,
    brand: AppPropTypes.taxonomy,
    image: AppPropTypes.image,
    thumbnailWidth: PropTypes.number,
    height: PropTypes.number,
    ratio: PropTypes.string,
    name: PropTypes.string,
    via: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    theme: PropTypes.oneOf(['box', null]),
    source: PropTypes.string,
    checked: PropTypes.bool,
    withButton: PropTypes.bool,
    withToggle: PropTypes.bool,
    withCheckbox: PropTypes.bool,
    onSubscribe: PropTypes.func,
    onUnsubscribe: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    author: null,
    brand: null,
    image: null,
    thumbnailWidth: null,
    height: 110,
    ratio: 'square',
    name: null,
    via: null,
    description: null,
    theme: null,
    source: null,
    checked: false,
    withButton: false,
    withToggle: false,
    withCheckbox: false,
    onSubscribe: null,
    onUnsubscribe: null,
    onChange: null,
    className: null,
};

function SubscriptionHorizontalCard({
    author,
    brand,
    handle,
    image,
    thumbnailWidth,
    height,
    ratio,
    name,
    via,
    description,
    theme,
    source,
    checked,
    withButton,
    withToggle,
    withCheckbox,
    onSubscribe,
    onUnsubscribe,
    onChange,
    className,
}) {
    const thumbnailRatio = getRatio(ratio, null);

    const { subscriptions: allSubscriptions = [] } = useSite();
    const { addMessage } = usePopupsContext();
    const { subscriptions: userSubscriptions = [], phone = null, email = null } = useUser() || {};
    const { subscribed = false, via: userVia } =
        userSubscriptions.find(({ id }) => id === handle) || {};

    const { subscribeAsync } = useSubscribe(handle);
    const { unsubscribeAsync } = useUnsubscribe(handle);
    const subscribeIsPending = useIsMutating({
        mutationKey: ['subscribe', handle],
    });
    const unsubscribeIsPending = useIsMutating({
        mutationKey: ['unsubscribe', handle],
    });
    const [editContactNeeded, setEditContactNeeded] = useState(null);

    const [defaultVia = null] = via || [];
    const tracking = useTracking();
    const onSubscribeChange = useCallback(
        (newSubscribed) => {
            if (newSubscribed && defaultVia === 'sms' && phone === null) {
                setEditContactNeeded('phone');
                return;
            }
            if (newSubscribed && defaultVia === 'email' && email === null) {
                setEditContactNeeded('email');
                return;
            }

            if (newSubscribed) {
                subscribeAsync({
                    source,
                }).then(() => {
                    if (onSubscribe !== null) {
                        onSubscribe();
                    }
                    tracking.trackSubscribe(handle, source);
                    addMessage('subscribe', {
                        subscription: allSubscriptions.find(
                            ({ handle: subscriptionHandle }) => subscriptionHandle === handle,
                        ),
                    });
                });
            } else {
                unsubscribeAsync().then(() => {
                    if (onUnsubscribe !== null) {
                        onUnsubscribe();
                    }
                    tracking.trackUnsubscribe(handle, source);
                    addMessage('unsubscribe', {
                        subscription: allSubscriptions.find(
                            ({ handle: subscriptionHandle }) => subscriptionHandle === handle,
                        ),
                    });
                });
            }
        },
        [
            handle,
            source,
            defaultVia,
            phone,
            email,
            subscribeAsync,
            unsubscribeAsync,
            onSubscribe,
            onUnsubscribe,
            tracking,
            allSubscriptions,
            addMessage,
        ],
    );

    const onViaChange = useCallback(
        (newVia) => {
            if (newVia === 'sms' && phone === null) {
                setEditContactNeeded('phone');
                return;
            }
            if (newVia === 'email' && email === null) {
                setEditContactNeeded('email');
                return;
            }
            subscribeAsync({
                subscription: handle,
                source,
                via: newVia,
            }).then(() => {
                if (onSubscribe !== null) {
                    onSubscribe();
                }
            });
        },
        [handle, source, subscribeAsync, onSubscribe, phone, email],
    );
    const onEditContactComplete = useCallback(() => {
        subscribeAsync({
            subscription: handle,
            source,
            via: editContactNeeded === 'phone' ? 'sms' : 'email',
        }).then(() => {
            if (onSubscribe !== null) {
                onSubscribe();
            }
        });
    }, [handle, source, editContactNeeded, subscribeAsync]);
    const onEditContactClosed = useCallback(() => {
        setEditContactNeeded(null);
    }, [setEditContactNeeded]);

    const { name: authorName, description: authorDescription = null } = author || {};
    const { description: brandDescription = null } = brand || {};

    const finalThumbnailWidth = thumbnailWidth || thumbnailRatio * height;
    const finalThumbnailHeight = thumbnailWidth !== null ? thumbnailWidth / thumbnailRatio : height;

    const onCheckboxChange = useCallback(
        (e) => {
            if (onChange !== null) {
                onChange(e.target.checked);
            }
        },
        [onChange],
    );

    return (
        <HorizontalCard
            // height={height}
            thumbnailRatio={thumbnailRatio}
            // responsive
            isLabel={withCheckbox}
            thumbnail={
                author !== null ? (
                    <AuthorImage
                        name={authorName}
                        width={finalThumbnailWidth}
                        height={finalThumbnailHeight}
                        media={image}
                        size="small"
                        className={styles.thumbnail}
                        pictureClassName={styles.picture}
                        responsive={thumbnailWidth === null}
                    />
                ) : (
                    <Image
                        width={finalThumbnailWidth}
                        height={finalThumbnailHeight}
                        media={image}
                        size="small"
                        className={styles.thumbnail}
                        pictureClassName={styles.picture}
                        responsive={thumbnailWidth === null}
                        // withoutSize
                    />
                )
            }
            onClick={withToggle && !subscribed ? () => onSubscribeChange(!subscribed) : null}
            className={classNames([
                styles.container,
                {
                    [styles.isAuthor]: author !== null,
                    [styles.isBrand]: brand !== null,
                    [styles.subscribed]: subscribed && !withCheckbox,
                },
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
        >
            <div className={styles.inner}>
                <CardTitle className={styles.name}>{name}</CardTitle>
                <CardDescription
                    className={styles.description}
                    html={description || authorDescription || brandDescription}
                />
                {via !== null && via.length > 1 && subscribed && !withCheckbox ? (
                    <div className={styles.via}>
                        <strong>Recevoir par :</strong>
                        <ViaButton
                            via={via}
                            value={userVia}
                            onChange={onViaChange}
                            className={styles.viaButton}
                        />
                    </div>
                ) : null}
                {withButton ? (
                    <RoundedButton
                        transparent={subscribed}
                        onClick={() => onSubscribeChange(!subscribed)}
                        disabled={unsubscribeIsPending || subscribeIsPending}
                        className={classNames([
                            styles.button,
                            {
                                [styles.unsubscribeButton]: subscribed,
                            },
                        ])}
                    >
                        {subscribed ? (
                            <FormattedMessage
                                defaultMessage="Me désabonner"
                                description="Button label"
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="M'abonner"
                                description="Button label"
                            />
                        )}
                    </RoundedButton>
                ) : null}
            </div>
            {withToggle ? (
                <span className={styles.actions}>
                    {subscribed ? (
                        <ToggleButton
                            value={subscribed}
                            className={styles.toggle}
                            loading={unsubscribeIsPending || subscribeIsPending}
                            onChange={onSubscribeChange}
                        />
                    ) : (
                        <ToggleIcon
                            checked={subscribed}
                            className={styles.toggle}
                            loading={unsubscribeIsPending || subscribeIsPending}
                        />
                    )}
                </span>
            ) : null}

            {withCheckbox ? (
                <span className={styles.actions}>
                    <input
                        type="checkbox"
                        className={styles.checkbox}
                        checked={checked}
                        onChange={onCheckboxChange}
                    />
                </span>
            ) : null}

            {withButton ? (
                <span className={styles.actions}>
                    {unsubscribeIsPending || subscribeIsPending ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            spin
                            className={classNames([styles.icon, styles.loadingIcon])}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={classNames([styles.icon, styles.checkIcon])}
                        />
                    )}
                </span>
            ) : null}
            {editContactNeeded !== null ? (
                <EditContactModal
                    type={editContactNeeded}
                    onComplete={onEditContactComplete}
                    onClosed={onEditContactClosed}
                />
            ) : null}
        </HorizontalCard>
    );
}

SubscriptionHorizontalCard.propTypes = propTypes;
SubscriptionHorizontalCard.defaultProps = defaultProps;

export default SubscriptionHorizontalCard;
