import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';
import getOptimalImageSize from '../../lib/getOptimalImageSize';

import { useRequestBase } from '../../contexts/RequestContext';
import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';

import baseFavicon from '../../assets/img/favicon/urbania.png';

const propTypes = {
    brand: AppPropTypes.brand,
    url: PropTypes.string,
    title: PropTypes.string,
    titleSuffix: PropTypes.string,
    description: PropTypes.string,
    image: AppPropTypes.image,
    favicon: AppPropTypes.image,
    keywords: PropTypes.arrayOf(PropTypes.string),
    rssUrl: PropTypes.string,
    atomUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    microformats: PropTypes.arrayOf(PropTypes.object),
    withoutTitleSuffix: PropTypes.bool,
};

const defaultProps = {
    brand: null,
    url: null,
    title: null,
    titleSuffix: null,
    description: null,
    image: null,
    favicon: null,
    keywords: null,
    rssUrl: null,
    atomUrl: null,
    microformats: null,
    withoutTitleSuffix: false,
};

function Metadata({
    brand: providedBrand,
    title,
    description,
    image,
    favicon,
    url,
    keywords,
    rssUrl,
    atomUrl,
    microformats,
    titleSuffix,
    withoutTitleSuffix,
}) {
    const { brand: brandParam = null } = useParams();
    const baseUri = useRequestBase();
    const defaultBrand = useDefaultBrand();
    const { handle: brandHandle = null } = providedBrand || {};
    const brand = useBrand(brandHandle || brandParam);
    const {
        label: brandLabel = null,
        description: brandDescription = null,
        images: { favicon: brandFavicon = null, share: brandShareImage = null } = {},
    } = brand || defaultBrand;
    const brandTitleSuffix = brandLabel !== null ? ` | ${brandLabel}` : '';
    const finalTitleSuffix = titleSuffix || brandTitleSuffix;
    const imageAtSize = getOptimalImageSize(image || brandShareImage, 1920, 1080);
    const finalFavicon = favicon || brandFavicon;
    const { url: faviconUrl = baseFavicon } = finalFavicon || {};
    const finalDescription = description || brandDescription;
    const finalKeywords = keywords !== null ? keywords.join(',') : null;
    let metaUrl = null;
    if (url !== null) {
        metaUrl = url.match(/^(https?)?:\/\//i) === null ? `${baseUri}${url}` : url;
    }
    const finalRssUrl = rssUrl;
    const finalAtomUrl = atomUrl;
    const finalTitle = [title, !withoutTitleSuffix ? finalTitleSuffix : null]
        .filter((it) => it !== null)
        .join('');
    return (
        <>
            <link rel="icon" href={faviconUrl} type="image/png" />

            {/* General */}
            {finalTitle !== null && finalTitle.length > 0 ? <title>{finalTitle}</title> : null}
            {finalDescription !== null ? (
                <meta name="description" content={finalDescription} />
            ) : null}
            {finalKeywords !== null ? <meta name="keywords" content={finalKeywords} /> : null}

            <link rel="canonical" href={metaUrl} />

            {/* Feeds */}
            {finalRssUrl !== null ? (
                <link rel="alternate" type="application/rss+xml" href={finalRssUrl} />
            ) : null}
            {finalAtomUrl !== null ? (
                <link rel="alternate" type="application/atom+xml" href={finalAtomUrl} />
            ) : null}

            {/* Open graph */}
            {imageAtSize !== null ? <meta property="og:image" content={imageAtSize.url} /> : null}
            {imageAtSize !== null ? (
                <meta property="og:image:url" content={imageAtSize.url} />
            ) : null}
            {imageAtSize !== null ? (
                <meta property="og:image:width" content={imageAtSize.width} />
            ) : null}
            {imageAtSize !== null ? (
                <meta property="og:image:height" content={imageAtSize.height} />
            ) : null}
            <meta property="og:title" content={title} />
            {finalDescription !== null ? (
                <meta property="og:description" content={finalDescription} />
            ) : null}
            {metaUrl !== null ? <meta property="og:url" content={metaUrl} /> : null}

            {/* Twitter */}
            <meta
                name="twitter:card"
                content={imageAtSize !== null ? 'summary_large_image' : 'summary'}
            />
            <meta name="twitter:title" content={title} />
            {finalDescription !== null ? (
                <meta name="twitter:description" content={finalDescription} />
            ) : null}
            {imageAtSize !== null ? <meta name="twitter:image" content={imageAtSize.url} /> : null}

            {/* Microformats */}
            {(microformats || []).map((it) => (
                <script
                    type="application/ld+json"
                    id={`${it[`@type`]}-${it.identifier}`}
                    key={`microformat-${it[`@type`]}-${it.identifier}`}
                >
                    {JSON.stringify(it)}
                </script>
            ))}
        </>
    );
}

Metadata.propTypes = propTypes;
Metadata.defaultProps = defaultProps;

export default Metadata;
