/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearch } from 'wouter';

import useCollectionsInfinite from '../../hooks/useCollectionsInfinite';
import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import IndexFilters from '../partials/IndexFilters';

import styles from '../../styles/pages/collections-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function CollectionsPage({ brandSlug, current, className, contentClassName, onEnter, onLeave }) {
    const filters = useFiltersQuery();
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};

    const search = useSearch();
    const { sort: sortQuery = null } = useMemo(() => queryString.parse(search), [search]);

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                pageType: 'index',
            });
        }
    }, [tracking, current, brand, filters]);

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
            ])}
        >
            <IndexDefaultHeader
                brand={brand}
                title={<FormattedMessage defaultMessage="Collections" description="Page title" />}
                className={styles.header}
            />
            <IndexFilters
                route="collections"
                sort={sortQuery}
                brand={brand}
                className={styles.filters}
            />
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        brand: brand.id,
                        ...filters,
                    }}
                    itemsBlocks={[
                        {
                            type: 'items',
                            itemsType: 'collections',
                            presentation: 'grid',
                            cardWidth: 350,
                            cardSize: 'big',
                            cardTheme: 'box-translucent',
                            cardRatio: 'horizontal',
                            count: 12,
                        },
                    ]}
                    hook={useCollectionsInfinite}
                    itemsType="collections"
                    cardType="vertical"
                    cardWithout={['brand-icon', 'type-icon']}
                />
            </main>
        </div>
    );
}

CollectionsPage.propTypes = propTypes;
CollectionsPage.defaultProps = defaultProps;

export default CollectionsPage;
