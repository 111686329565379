/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useSearch } from 'wouter';

import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import IndexFilters from '../partials/IndexFilters';
import PageSectionTitle from '../typography/PageSectionTitle';

import styles from '../../styles/pages/videos-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function VideosPage({ brandSlug, current, className, contentClassName, onEnter, onLeave }) {
    const filters = useFiltersQuery();
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};

    const search = useSearch();
    const { sort: sortQuery = null } = useMemo(() => queryString.parse(search), [search]);

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                pageType: 'index',
            });
        }
    }, [tracking, current, brand, filters]);

    const blocks = useMemo(
        () => [
            {
                type: 'collections-row',
                presentation: 'row',
                // title: (
                //     <PageSectionTitle className={styles.sectionTitle}>
                //         Séries
                //     </PageSectionTitle>
                // ),
                count: 12,
                query: {
                    brand: brand.id,
                    type: 'serie',
                },
                cardType: 'over',
                cardRatio: 'vertical',
                cardWithout: ['brand-icon', 'text'],
                cardWidth: 200,
                separator: true,
            },
        ],
        [brand],
    );

    return (
        <div
            className={classNames([
                styles.container,
                styles[brandHandle],
                className,
                contentClassName,
            ])}
        >
            <IndexDefaultHeader
                brand={brand}
                title={<FormattedMessage defaultMessage="Vidéos" description="Page title" />}
                className={styles.header}
            />
            <IndexFilters
                route="videos"
                sort={sortQuery}
                brand={brand}
                className={styles.filters}
            />
            <main className={styles.content}>
                <InfiniteItemsBlocks
                    query={{
                        type: 'video',
                        brand: brand.id,
                        ...filters,
                    }}
                    blocks={blocks}
                    // itemsBlocks={[
                    //     {
                    //         type: 'items',
                    //         itemsType: 'documents',
                    //         presentation: 'grid',
                    //         cardType: 'vertical',
                    //         // cardRatio: 'vertical',
                    //         cardTheme: 'box-translucent',
                    //         cardWidth: 250,
                    //         cardWithout: brandSlug !== null ? ['brand-icon'] : [],
                    //         cardSize: {
                    //             large: null,
                    //             medium: null,
                    //             default: 'small',
                    //         },
                    //         // cardSize: 'big',
                    //         fixedColumns: [2, 3, 4, 6],
                    //         count: 12,
                    //     },
                    //     {
                    //         type: 'items',
                    //         itemsType: 'documents',
                    //         presentation: 'row',
                    //         cardType: 'over',
                    //         cardRatio: 'vertical',
                    //         cardTheme: 'outline',
                    //         cardWidth: 300,
                    //         cardWithout: brandSlug !== null ? ['brand-icon'] : [],
                    //         // cardSize: 'big',
                    //         fixedColumns: [2, 3, 4, 6],
                    //         count: 12,
                    //     },
                    // ]}
                    cardWithout={['brand-icon']}
                />
            </main>
        </div>
    );
}

VideosPage.propTypes = propTypes;
VideosPage.defaultProps = defaultProps;

export default VideosPage;
