/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import SubscriptionWizard from '../partials/SubscriptionWizard';
import PromotionCard from './PromotionCard';

import styles from '../../styles/cards/subscription-promotion-card.module.css';

// @TODO: Swtch input type
const propTypes = {
    handle: PropTypes.string,
    source: PropTypes.string,
    subscription: PropTypes.string,
    image: AppPropTypes.image,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf([null, 'image-popout']),
    onComplete: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    handle: null,
    source: null,
    subscription: 'urbania',
    image: null,
    title: 'Abonnez-vous à<br/>l’infolettre URBANIA',
    description: 'Chaque lundi et mercredi : nos contenus qui soulèvent les passions.',
    theme: 'urbania',
    onComplete: null,
    className: null,
};

function SubscriptionPromotionCard({
    source,
    handle,
    subscription,
    className,
    onComplete,
    ...props
}) {
    const { subscriptions: allSubscriptions } = useSite();
    const { addMessage } = usePopupsContext();
    const onWizardComplete = useCallback(
        (newUser) => {
            addMessage('subscribe', {
                subscription: allSubscriptions.find(
                    ({ handle: subscriptionHandle }) => subscriptionHandle === subscription,
                ),
            });
            if (onComplete !== null) {
                onComplete(newUser);
            }
        },
        [onComplete, addMessage, subscription],
    );
    return (
        <PromotionCard
            callToAction={
                <SubscriptionWizard
                    // withPopout
                    // withFull
                    subscription={subscription}
                    source={source || handle || `promotion-${subscription}`}
                    onComplete={onWizardComplete}
                />
            }
            className={classNames([styles.container, className])}
            {...props}
        />
    );
}

SubscriptionPromotionCard.propTypes = propTypes;
SubscriptionPromotionCard.defaultProps = defaultProps;

export default SubscriptionPromotionCard;
