/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useSearch } from 'wouter';

import { useSubscribe, useUnsubscribe } from '../../hooks/useSubscription';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import SubscriptionVerticalCard from '../cards/SubscriptionVerticalCard';
import PageSectionDescription from '../typography/PageSectionDescription';
import PageSectionTitle from '../typography/PageSectionTitle';

import styles from '../../styles/panels/account-subscriptions-panel.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AccountSubscriptionsPanel({ className }) {
    const { subscriptions: allSubscriptions } = useSite();

    const search = useSearch();
    const {
        unsubscribe: unsubscribeQuery = null,
        subscribe: subscribeQuery = null,
        source: sourceQuery = null,
    } = useMemo(() => queryString.parse(search) || {}, [search]);
    const { addMessage } = usePopupsContext();
    const { subscribeAsync } = useSubscribe(subscribeQuery);
    const { unsubscribeAsync } = useUnsubscribe(unsubscribeQuery);

    useEffect(() => {
        if (subscribeQuery !== null) {
            subscribeAsync({
                subscription: subscribeQuery,
                source: sourceQuery || 'account',
            }).then(() => {
                addMessage('subscribe', {
                    subscription: allSubscriptions.find(({ handle }) => handle === subscribeQuery),
                });
            });
        } else if (unsubscribeQuery !== null) {
            unsubscribeAsync(unsubscribeQuery).then(() => {
                addMessage('unsubscribe', {
                    subscription: allSubscriptions.find(
                        ({ handle }) => handle === unsubscribeQuery,
                    ),
                });
            });
        }
    }, [unsubscribeQuery, subscribeQuery, sourceQuery, addMessage, allSubscriptions]);

    const brandsNotifications = allSubscriptions.filter(
        ({ type, brand = null }) => type === 'notification' && brand !== null,
    );
    const authorsNotifications = allSubscriptions.filter(
        ({ type, author = null }) => type === 'notification' && author !== null,
    );
    const subscriptionQuery = subscribeQuery || unsubscribeQuery;
    const subscriptions = allSubscriptions
        .filter(({ type }) => type !== 'notification')
        .sort(({ handle: aHandle }, { handle: bHandle }) => {
            if (aHandle === subscriptionQuery) {
                return -1;
            }
            if (bHandle === subscriptionQuery) {
                return 1;
            }
            return 0;
        });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <section className={styles.section}>
                <PageSectionTitle className={styles.title}>Infolettres</PageSectionTitle>
                <PageSectionDescription className={styles.description}>
                    Abonnez-vous par sms ou par courriel à nos infolettres
                </PageSectionDescription>
                <div className={styles.subscriptions}>
                    {subscriptions.map((subscription) => (
                        <SubscriptionHorizontalCard
                            {...subscription}
                            thumbnailWidth={75}
                            source="account"
                            theme="box"
                            withToggle
                            className={styles.subscription}
                        />
                    ))}
                </div>
            </section>

            <section className={styles.section}>
                <PageSectionTitle className={styles.title}>Notifications</PageSectionTitle>
                <PageSectionDescription className={styles.description}>
                    Recevez une notification par sms pour chaque contenu publié
                </PageSectionDescription>

                <div className={styles.authors}>
                    {authorsNotifications.map((subscription) => (
                        <SubscriptionHorizontalCard
                            {...subscription}
                            thumbnailWidth={75}
                            source="account"
                            theme="notification"
                            ratio="square"
                            withToggle
                            className={styles.notification}
                        />
                    ))}
                </div>

                <div className={styles.brands}>
                    {brandsNotifications.map((subscription) => (
                        <SubscriptionVerticalCard
                            {...subscription}
                            source="account"
                            theme="notification"
                            ratio="square"
                            withToggle
                            className={styles.notification}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}

AccountSubscriptionsPanel.propTypes = propTypes;
AccountSubscriptionsPanel.defaultProps = defaultProps;

export default AccountSubscriptionsPanel;
