/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import { useTracking } from '@folklore/tracking';
import { animated, useSpring } from '@react-spring/web';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import getDisplayName from '../../lib/getDisplayName';

import { MODAL_NAMESPACE } from '../../contexts/PopupsContext';
import PopupPortal from './PopupPortal';

import styles from '../../styles/popups/modal.module.css';

const propTypes = {
    id: PropTypes.string,
    opened: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'bottom']),
    withBackdrop: PropTypes.bool,
    withTransition: PropTypes.bool,
    withScrollLock: PropTypes.bool,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    children: PropTypes.node,
    requestClose: PropTypes.func,
    onClickBackdrop: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
};

const defaultProps = {
    id: null,
    opened: true,
    position: 'center',
    withBackdrop: false,
    withTransition: false,
    withScrollLock: false,
    className: null,
    innerClassName: null,
    children: null,
    requestClose: null,
    onClickBackdrop: null,
    onOpened: null,
    onClosed: null,
};

const Modal = ({
    id,
    opened,
    position,
    withBackdrop,
    withTransition,
    withScrollLock,
    className,
    innerClassName,
    children,
    requestClose,
    onClickBackdrop,
    onOpened,
    onClosed,
}) => {
    const name = getDisplayName(children);
    const finalId = useMemo(() => id || name || 'Modal', [id, name]);
    const [verticalPosition, horizontalPosition = verticalPosition] = position.split(' ');
    const [isTransitioning, setIsTransitioning] = useState(false);
    const spring = useSpring({
        from: { y: opened ? '100%' : '0%', opacity: opened ? 0 : 1 },
        to: { y: opened ? '0%' : '100%', opacity: opened ? 1 : 0 },
        onStart: () => {
            setIsTransitioning(true);
        },
        onResolve: () => {
            setIsTransitioning(false);
            if (opened && onOpened !== null) {
                onOpened();
            } else if (!opened && onClosed !== null) {
                onClosed();
            }
        },
    });

    const modalRef = useRef();
    useEffect(() => {
        if (opened && withScrollLock) {
            disableBodyScroll(modalRef.current);
        }
        return () => {
            if (opened && withScrollLock) {
                enableBodyScroll(modalRef.current);
            }
        };
    }, [opened, withScrollLock]);

    const tracking = useTracking();
    useEffect(() => {
        tracking.trackModal(id, opened ? 'open' : 'close');
    }, [id, opened]);

    return (
        <PopupPortal id={finalId} requestClose={requestClose} namespace={MODAL_NAMESPACE}>
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.isTransitioning]: withTransition && isTransitioning,
                        [styles[verticalPosition]]: verticalPosition !== null,
                        [styles[horizontalPosition]]: horizontalPosition !== null,
                    },
                    className,
                ])}
                ref={modalRef}
            >
                <animated.div
                    style={withTransition ? spring : null}
                    className={classNames([styles.inner, innerClassName])}
                    tabIndex="-1"
                >
                    {children}
                </animated.div>
                {withBackdrop ? (
                    <animated.button
                        type="button"
                        className={styles.backdrop}
                        onClick={onClickBackdrop}
                        style={{
                            opacity: spring.opacity,
                        }}
                    />
                ) : null}
            </div>
        </PopupPortal>
    );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
