/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useScrollEnter from '../../hooks/useScrollEnter';
import * as AppPropTypes from '../../lib/PropTypes';
import getBlocksWithAddons from '../../lib/getBlocksWithAddons';

import Blocks from '../blocks/Blocks';
import BrandHeader from '../headers/BrandHeader';
// import CookiesModal from '../popups/CookiesModal';
// import SubscriptionModal from '../popups/SubscriptionModal';
import Bump from '../partials/Bump';

import styles from '../../styles/pages/home-page-blocks.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    header: PropTypes.node,
    blocks: AppPropTypes.blocks.isRequired,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    blocksClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brand: null,
    header: null,
    current: false,
    className: null,
    contentClassName: null,
    blocksClassName: null,
    onEnter: null,
    onLeave: null,
};

function HomePageBlocks({
    brand,
    header,
    blocks,
    current,
    onEnter,
    onLeave,
    className,
    contentClassName,
    blocksClassName,
}) {
    const { id: brandId = null, handle: brandHandle = null, sections = [] } = brand || {};

    const blocksWithAddons = useMemo(
        () =>
            getBlocksWithAddons(blocks, {
                addons: [
                    {
                        block: 'ad',
                        max: null,
                        interval: 1,
                        minHeight: 2,
                    },
                ],

                addonsProps: {
                    ad: ({ count = 0 }) => ({
                        slot: count === 0 ? 'fullwidth_native' : 'fullwidth',
                    }),
                },
                minSpaceBetween: 1,
            }),
        [blocks],
    );

    const [bumperPlaying, setBumperPlaying] = useState(brandHandle !== null && current);
    const [bumperMounted, setBumperMounted] = useState(bumperPlaying);
    useEffect(() => {
        if (brandHandle !== null && current) {
            setBumperPlaying(true);
        }
    }, [brandHandle]);

    const onBumperEnded = useCallback(() => {
        setBumperPlaying(false);
    }, [setBumperPlaying]);

    const onBumperFail = useCallback(() => {
        setBumperMounted(false);
    }, [setBumperMounted]);

    useEffect(() => {
        let timeout = null;
        if (bumperMounted && !bumperPlaying) {
            timeout = setTimeout(() => {
                setBumperMounted(false);
            }, 500);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [bumperMounted, bumperPlaying]);

    const { ref: endRef, visible: endVisible = false } = useIsVisible({
        disabled: !current,
        // rootMargin: `100px 0px`,
    });

    useScrollEnter({
        leave: endVisible,
        disabled: !current,
        onEnter,
        onLeave,
    });

    const tracking = useTracking();
    useEffect(() => {
        if (current) {
            tracking.trackPageView({
                brand,
                pageType: 'home',
            });
        }
    }, [tracking, current, brand]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.bumperIsVisible]: bumperPlaying,
                },
                styles[brandHandle],
                className,
                contentClassName,
            ])}
            data-brand={brandHandle}
        >
            {bumperMounted ? (
                <Bump
                    brand={brand}
                    playing={bumperPlaying}
                    onEnded={onBumperEnded}
                    onFail={onBumperFail}
                    className={styles.bumper}
                />
            ) : null}
            {header || <BrandHeader {...brand} className={styles.header} />}

            <Blocks
                blocks={blocksWithAddons}
                className={classNames([styles.blocks, blocksClassName])}
                blockClassName={styles.block}
            />
            <div ref={endRef} style={{ height: 1 }} />
        </div>
    );
}

HomePageBlocks.propTypes = propTypes;
HomePageBlocks.defaultProps = defaultProps;

export default HomePageBlocks;
