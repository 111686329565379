import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearch } from 'wouter';

import PillButton from '../buttons/PillButton';
import MenuLabel from '../typography/MenuLabel';

import styles from '../../styles/menus/sort-menu.module.css';

const propTypes = {
    url: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    labelClassName: null,
    className: null,
};

function SortMenu({ url, labelClassName, className }) {
    const search = useSearch();
    const { sort: sortParam = null } = useMemo(() => queryString.parse(search), [search]);
    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.inner}>
                <PillButton
                    href={`${url}`}
                    compact
                    className={classNames([
                        styles.filter,
                        {
                            [styles.active]: sortParam === null,
                        },
                    ])}
                >
                    <FormattedMessage defaultMessage="Plus récents" description="Filter label" />
                </PillButton>
                <PillButton
                    href={`${url}?sort=popular`}
                    compact
                    className={classNames([
                        styles.filter,
                        {
                            [styles.active]: sortParam === 'popular',
                        },
                    ])}
                >
                    <FormattedMessage defaultMessage="Plus populaires" description="Filter label" />
                </PillButton>
            </div>
        </div>
    );
}

SortMenu.propTypes = propTypes;
SortMenu.defaultProps = defaultProps;

export default SortMenu;
