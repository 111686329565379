/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUser } from '@folklore/auth';
import { useTracking } from '@folklore/tracking';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSubscribe, useUnsubscribe } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import RoundedButton from '../buttons/RoundedButton';
import ToggleIcon from '../icons/ToggleIcon';
import Image from '../partials/Image';
import CardDescription from '../typography/CardDescription';
import CardTitle from '../typography/CardTitle';
import VerticalCard from './VerticalCard';

import styles from '../../styles/cards/subscription-vertical-card.module.css';

const propTypes = {
    handle: PropTypes.string.isRequired,
    author: AppPropTypes.author,
    brand: AppPropTypes.taxonomy,
    image: AppPropTypes.image,
    ratio: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf(['box', null]),
    source: PropTypes.string,
    withToggle: PropTypes.bool,
    onSubscribe: PropTypes.func,
    onUnsubscribe: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    author: null,
    brand: null,
    image: null,
    ratio: 'square',
    name: null,
    description: null,
    theme: null,
    source: null,
    withToggle: false,
    onSubscribe: null,
    onUnsubscribe: null,
    className: null,
};

function SubscriptionVerticalCard({
    author,
    brand,
    handle,
    image,
    ratio,
    name,
    description,
    theme,
    source,
    withToggle,
    onSubscribe,
    onUnsubscribe,
    className,
}) {
    const thumbnailRatio = getRatio(ratio, null);

    const { subscriptions: allSubscriptions = [] } = useSite();
    const { addMessage } = usePopupsContext();
    const { subscriptions: userSubscriptions = [] } = useUser() || {};
    const { subscribed = false } = userSubscriptions.find(({ id }) => id === handle) || {};

    const { subscribeAsync, isPending: subscribeIsPending } = useSubscribe();
    const { unsubscribeAsync, isPending: unsubscribeIsPending } = useUnsubscribe();

    const tracking = useTracking();
    const onSubscribeChange = useCallback(
        (newSubscribed) => {
            if (newSubscribed) {
                subscribeAsync({
                    subscription: handle,
                    source,
                }).then(() => {
                    if (onSubscribe !== null) {
                        onSubscribe();
                    }
                    tracking.trackSubscribe(handle, source);
                    addMessage('subscribe', {
                        subscription: allSubscriptions.find(
                            ({ handle: subscriptionHandle }) => subscriptionHandle === handle,
                        ),
                    });
                });
            } else {
                unsubscribeAsync(handle).then(() => {
                    if (onUnsubscribe !== null) {
                        onUnsubscribe();
                    }
                    tracking.trackUnsubscribe(handle, source);
                    addMessage('unsubscribe', {
                        subscription: allSubscriptions.find(
                            ({ handle: subscriptionHandle }) => subscriptionHandle === handle,
                        ),
                    });
                });
            }
        },
        [
            handle,
            unsubscribeAsync,
            onUnsubscribe,
            tracking,
            source,
            onSubscribe,
            onUnsubscribe,
            allSubscriptions,
            addMessage,
        ],
    );

    const { description: authorDescription = null } = author || {};
    const { description: brandDescription = null } = brand || {};

    return (
        <VerticalCard
            // height={height}
            // thumbnailRatio={thumbnailRatio}
            // responsive
            thumbnail={
                <Image
                    media={image}
                    size="small"
                    className={styles.image}
                    pictureClassName={styles.picture}
                    responsive
                    ratio={thumbnailRatio}
                    // withoutSize
                />
            }
            onClick={withToggle ? () => onSubscribeChange(!subscribed) : null}
            className={classNames([
                styles.container,
                {
                    [styles.isAuthor]: author !== null,
                    [styles.isBrand]: brand !== null,
                    [styles.subscribed]: subscribed,
                },
                styles[camelCase(theme)],
                className,
            ])}
            labelClassName={styles.label}
            thumbnailClassName={styles.thumbnail}
        >
            <div className={styles.inner}>
                <CardTitle className={styles.name}>{name}</CardTitle>
                <CardDescription
                    className={styles.description}
                    html={description || authorDescription || brandDescription}
                />
                {withToggle ? (
                    <ToggleIcon
                        checked={subscribed}
                        className={styles.toggle}
                        loading={unsubscribeIsPending || subscribeIsPending}
                    />
                ) : (
                    <RoundedButton
                        transparent={subscribed}
                        onClick={() => onSubscribeChange(!subscribed)}
                        disabled={unsubscribeIsPending || subscribeIsPending}
                        className={classNames([
                            styles.button,
                            {
                                [styles.unsubscribeButton]: subscribed,
                            },
                        ])}
                    >
                        {subscribed ? (
                            <FormattedMessage
                                defaultMessage="Me désabonner"
                                description="Button label"
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="M'abonner"
                                description="Button label"
                            />
                        )}
                    </RoundedButton>
                )}
            </div>
            {!withToggle ? (
                <>
                    {unsubscribeIsPending || subscribeIsPending ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            spin
                            className={classNames([styles.icon, styles.loadingIcon])}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={classNames([styles.icon, styles.checkIcon])}
                        />
                    )}
                </>
            ) : null}
        </VerticalCard>
    );
}

SubscriptionVerticalCard.propTypes = propTypes;
SubscriptionVerticalCard.defaultProps = defaultProps;

export default SubscriptionVerticalCard;
