import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
    subscribe: ({ subscription } = {}) => {
        const { labels = null } = subscription || {};
        const { to: label = null } = labels || {};
        return label !== null ? (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant abonné·e {label}!"
                description="Message"
                values={{ label }}
            />
        ) : (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant abonné·e!"
                description="Message"
            />
        );
    },
    unsubscribe: ({ subscription } = {}) => {
        const { labels = null } = subscription || {};
        const { of: label = null } = labels || {};
        return label !== null ? (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant désabonné·e {label}!"
                description="Message"
                values={{ label }}
            />
        ) : (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant désabonné·e!"
                description="Message"
            />
        );
    },
    hi: ({ isNew = true, user } = {}) => {
        const { firstname = null } = user || {};
        if (isNew) {
            return firstname !== null ? (
                <FormattedMessage
                    defaultMessage="Bonjour {firstname}!"
                    description="Message"
                    values={{ firstname }}
                />
            ) : (
                <FormattedMessage defaultMessage="Bonjour!" description="Message" />
            );
        }
        return firstname !== null ? (
            <FormattedMessage
                defaultMessage="Rebonjour {firstname}!"
                description="Message"
                values={{ firstname }}
            />
        ) : (
            <FormattedMessage defaultMessage="Rebonjour!" description="Message" />
        );
    },
};
