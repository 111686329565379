/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import classNames from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateProfile } from '../../hooks/useProfile';

import FormButton from '../buttons/FormButton';
import DateField from '../fields/DateField';
import TextField from '../fields/TextField';
import FormStatus from '../partials/FormStatus';
import FormControl from './FormControl';
import FormRow from './FormRow';

import styles from '../../styles/forms/profile-form.module.css';

const propTypes = {
    action: PropTypes.string.isRequired,
    className: PropTypes.string,
    onComplete: PropTypes.func,
};

const defaultProps = {
    className: null,
    onComplete: null,
};

function ProfileForm({ action, className, onComplete }) {
    const user = useUser();
    const { updateProfileAsync } = useUpdateProfile();
    const formFields = useMemo(
        () => ['firstname', 'lastname', 'postalcode', 'birthdate'].filter(Boolean),
        [],
    );

    const now = useMemo(() => new Date().toISOString().split('T')[0], []);

    const initialValue = useMemo(() => pick(user, formFields), [user, formFields]);

    const onFormComplete = useCallback(() => {
        if (onComplete !== null) {
            onComplete();
        }
    }, [onComplete]);

    const { fields, onSubmit, status, errors } = useForm({
        fields: formFields,
        initialValue,
        postForm: (formAction, data) => updateProfileAsync(data),
        onComplete: onFormComplete,
    });
    return (
        <form
            action={action}
            method="post"
            onSubmit={onSubmit}
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                },
                className,
            ])}
        >
            <FormRow className={styles.formRow}>
                <FormControl
                    label={<FormattedMessage defaultMessage="Prénom" description="Field label" />}
                    className={classNames([styles.formControl, styles.firstName])}
                    {...fields.firstname}
                    withoutErrors
                >
                    <TextField withBorder {...fields.firstname} className={styles.field} />
                </FormControl>
                <FormControl
                    label={<FormattedMessage defaultMessage="Nom" description="Field label" />}
                    className={styles.formControl}
                    {...fields.lastname}
                    withoutErrors
                >
                    <TextField withBorder {...fields.lastname} className={styles.field} />
                </FormControl>
            </FormRow>
            <FormRow alwaysHorizontal className={styles.formRow}>
                <FormControl
                    label={
                        <FormattedMessage defaultMessage="Code postal" description="Field label" />
                    }
                    className={styles.formControl}
                    {...fields.postalcode}
                    withoutErrors
                >
                    <TextField withBorder {...fields.postalcode} className={styles.field} />
                </FormControl>
                <FormControl
                    label={
                        <FormattedMessage
                            defaultMessage="Date de naissance"
                            description="Field label"
                        />
                    }
                    className={styles.formControl}
                    {...fields.birthdate}
                    withoutErrors
                >
                    <DateField
                        withBorder
                        {...fields.birthdate}
                        min="1900-01-01"
                        max={now}
                        className={styles.field}
                    />
                </FormControl>
            </FormRow>
            <div className={styles.actions}>
                <FormButton
                    type="submit"
                    dark
                    className={styles.button}
                    disabled={status === 'loading'}
                >
                    <FormattedMessage defaultMessage="Enregistrer" description="Button label" />
                </FormButton>
                <FormStatus status={status} className={styles.status} />
            </div>
        </form>
    );
}

ProfileForm.propTypes = propTypes;
ProfileForm.defaultProps = defaultProps;

export default ProfileForm;
