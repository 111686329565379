/* eslint-disable react/forbid-prop-types */
import { useVisualViewport } from '@folklore/hooks';
import { animated, useSpring } from '@react-spring/web';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import useKeyboardKeys, { KEYCODES } from '../../hooks/useKeyboardKeys';
import getDisplayName from '../../lib/getDisplayName';

import { usePopupsContext } from '../../contexts/PopupsContext';

import styles from '../../styles/popups/popout.module.css';

const propTypes = {
    id: PropTypes.string,
    detach: PropTypes.bool,
    initialStyle: PropTypes.object,
    attachedStyle: PropTypes.object,
    detachedStyle: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
    onDetach: PropTypes.func,
    onAttach: PropTypes.func,
    requestClose: PropTypes.func,
};

const defaultProps = {
    id: null,
    detach: false,
    initialStyle: null,
    attachedStyle: null,
    detachedStyle: null,
    children: null,
    className: null,
    modalClassName: null,
    onDetach: null,
    onAttach: null,
    requestClose: null,
};

function Popout({
    id,
    detach: shouldDetach,
    initialStyle,
    attachedStyle,
    detachedStyle,
    children,
    className,
    modalClassName,
    onDetach,
    onAttach,
    requestClose,
}) {
    const { registerModal, unregisterModal } = usePopupsContext();
    const name = getDisplayName(children);
    const finalModalId = useMemo(() => id || name || `modal-${new Date().getTime()}`, [id, name]);

    const [detach, setDetach] = useState(false);
    const [detached, setDetached] = useState(false);
    const [wrapperRect, setWrapperRect] = useState(null);
    const wrapperRef = useRef(null);
    const modalRef = useRef(null);
    const {
        width: viewportWidth = 0,
        height: viewportHeight = 0,
        offsetTop: viewportTop = 0,
        updateViewport,
    } = useVisualViewport();
    let newModalStyle = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        immediate: true,
        ...initialStyle,
    };
    if (detach && shouldDetach) {
        newModalStyle = {
            top: 0,
            left: 0,
            width: viewportWidth,
            height: viewportHeight,
            x: 0,
            y: viewportTop,
            immediate: false,
            ...detachedStyle,
        };
    } else if ((shouldDetach || detach) && wrapperRect !== null) {
        newModalStyle = {
            top: 0,
            left: 0,
            width: wrapperRect.width,
            height: wrapperRect.height,
            x: wrapperRect.left,
            y: wrapperRect.top,
            immediate: shouldDetach,
            ...attachedStyle,
        };
    }
    const modalStyle = useSpring({
        ...newModalStyle,
        onResolve: () => {
            if (shouldDetach && !detach) {
                setDetach(true);
                setDetached(false);
            } else if (!shouldDetach && detach) {
                setDetach(false);
            } else if (detach) {
                setDetached(true);
            } else if (!detach) {
                setDetached(false);
                setWrapperRect(null);
            }
        },
        // config: {
        //     duration: 5000,
        // }
        // onRest: () => {
        //     setWrapperRect(wrapperRef.current.getBoundingClientRect());
        // },
    });
    useLayoutEffect(() => {
        // if (shouldDetach) {
        setWrapperRect(wrapperRef.current.getBoundingClientRect());
        updateViewport();
        // }
    }, [shouldDetach, setWrapperRect, updateViewport]);

    useEffect(() => {
        if (detach && onDetach !== null) {
            onDetach();
        } else if (!detach && onAttach !== null) {
            onAttach();
        }
    }, [detach]);

    const shouldRegisterModal = shouldDetach || detach || detached;
    useEffect(() => {
        if (!shouldRegisterModal) {
            return () => {};
        }
        registerModal(finalModalId, {
            outsideContainer: true,
            requestClose,
        });
        return () => {
            unregisterModal(finalModalId);
        };
    }, [shouldRegisterModal, finalModalId, registerModal, unregisterModal, requestClose]);

    // useEffect(() => {
    //     if (!shouldRegisterModal) {
    //         return () => {};
    //     }
    //     disableBodyScroll(modalRef.current);
    //     return () => {
    //         enableBodyScroll(modalRef.current);
    //     };
    // }, [shouldRegisterModal]);

    useKeyboardKeys({
        [KEYCODES.ESCAPE]: requestClose,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.detached]: detach,
                },
                className,
            ])}
        >
            <div
                className={styles.wrapper}
                ref={wrapperRef}
                style={{
                    height:
                        (shouldDetach || detach) && wrapperRect !== null
                            ? wrapperRect.height
                            : null,
                    width:
                        (shouldDetach || detach) && wrapperRect !== null ? wrapperRect.width : null,
                }}
            >
                <animated.div
                    className={classNames([styles.modal, modalClassName])}
                    style={{
                        ...modalStyle,
                        width: modalStyle.width.to((width) =>
                            (shouldDetach || detach || detached) && width !== 0 ? width : null,
                        ),
                        height: modalStyle.height.to((height) =>
                            (shouldDetach || detach || detached) && height !== 0 ? height : null,
                        ),
                    }}
                    ref={modalRef}
                >
                    {children}
                </animated.div>
            </div>
        </div>
    );
}

Popout.propTypes = propTypes;
Popout.defaultProps = defaultProps;

export default Popout;
