/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTransition, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import BrandLogo from '../icons/BrandLogoHeader';
import BasicButton from './BasicButton';

import styles from '../../styles/buttons/brand-logo-button.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    withSponsor: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
    sponsorDelay: PropTypes.number,
    sponsorDuration: PropTypes.number,
    className: PropTypes.string,
    logoClassName: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    className: null,
    withSponsor: false,
    sponsorDelay: 1000,
    sponsorDuration: 3500,
    logoClassName: null,
    buttonRef: null,
};

function BrandLogoButton({
    brand,
    withSponsor,
    sponsorDelay,
    sponsorDuration,
    className,
    logoClassName,
    buttonRef,
    ...props
}) {
    const url = useUrlGenerator();
    const { slug = null } = brand || {};
    // console.log(handle);
    const transitions = useTransition([brand], {
        initial: {
            opacity: 1,
            y: '0%',
        },
        from: {
            opacity: 0,
            y: '-100%',
        },
        enter: {
            opacity: 1,
            y: '0%',
        },
        leave: {
            opacity: 0,
            y: '100%',
        },
        keys: ({ id, handle }) => id || handle,
        // config: {
        //     duration: 5000,
        // },
    });

    const [sponsorVisible, setSponsorVisible] = useState(false);

    useEffect(() => {
        if (withSponsor !== 'auto') {
            return () => {};
        }
        setSponsorVisible(false);
        let timeout = setTimeout(() => {
            setSponsorVisible(true);

            timeout = setTimeout(() => {
                setSponsorVisible(false);
            }, sponsorDuration);
        }, sponsorDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [brand, withSponsor, sponsorDelay, sponsorDuration]);

    return (
        <BasicButton
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            ref={buttonRef}
            href={
                slug !== null
                    ? url('home', {
                          brand: slug,
                      })
                    : '/'
            }
            {...props}
        >
            {transitions(({ y, ...style }, { handle }, { phase }) => (
                <animated.span
                    className={classNames([
                        styles.logoContainer,
                        styles[phase],
                        styles[handle],
                    ])}
                    style={{
                        ...style,
                        y,
                        // transform: y.to((value) => index > 0 ? `translate(-50%, ${value})` : `translate(0px, ${value})`),
                    }}
                >
                    <BrandLogo
                        brand={handle}
                        withSponsor={withSponsor === 'auto' ? sponsorVisible : withSponsor}
                        className={classNames([styles.logo, logoClassName])}
                    />
                </animated.span>
            ))}
        </BasicButton>
    );
}

BrandLogoButton.propTypes = propTypes;
BrandLogoButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <BrandLogoButton {...props} buttonRef={ref} />);
