/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import useDocuments from '../../hooks/useDocuments';
import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import * as AppPropTypes from '../../lib/PropTypes';
import { getPrefixedProps } from '../../lib/utils';

import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';

import styles from '../../styles/headers/featured-list-header.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    count: PropTypes.number,
    withoutLoader: PropTypes.bool,
    cardTheme: PropTypes.string,
    cardWith: PropTypes.arrayOf(PropTypes.string),
    cardWithout: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    cardClassName: PropTypes.string,
    onLoaded: PropTypes.func,
};

const defaultProps = {
    brand: null,
    query: null,
    items: null,
    count: 2,
    withoutLoader: false,
    cardTheme: null,
    cardWith: null,
    cardWithout: null,
    className: null,
    innerClassName: null,
    cardClassName: null,
    onLoaded: null,
};

function FeaturedListHeader({
    brand,
    query,
    items: providedItems,
    count,
    withoutLoader,
    cardTheme,
    cardWith,
    cardWithout,
    className,
    innerClassName,
    cardClassName,
    onLoaded,
    ...props
}) {
    const { handle: brandHandle = null } = brand || {};
    const { items = providedItems } = useDocuments(
        {
            count, // featured + overCount
            ...query,
        },
        {
            enabled: !withoutLoader,
        },
    );

    useEffect(() => {
        if (items !== null && onLoaded !== null) {
            onLoaded(items);
        }
    }, [items]);

    const placeholderItems = usePlaceholderItems(count);

    return (
        <div
            className={classNames([
                styles.container,
                styles[camelCase(brandHandle)],
                styles[camelCase(cardTheme)],
                className,
            ])}
        >
            <div className={classNames([styles.inner, innerClassName])}>
                {(items || placeholderItems).map((it, index) => {
                    const itemTheme = cardTheme || ['left-center', 'right-center'][index % 2];
                    return (
                        <DocumentFeaturedCard
                            key={`item-${index}`}
                            {...it}
                            placeholder={it === null}
                            theme={itemTheme}
                            className={classNames([
                                styles.item,
                                styles[camelCase(itemTheme)],
                                {
                                    [styles.isOver]:
                                        itemTheme !== null && itemTheme.indexOf('over') !== -1,
                                },
                                cardClassName,
                            ])}
                            imageLoading={null}
                            innerClassName={styles.itemInner}
                            labelClassName={styles.itemLabel}
                            imageContainerClassName={styles.imageContainer}
                            imageClassName={styles.image}
                            titleClassName={styles.title}
                            widthTitleAndAuthorOnly
                            withBreakpoints
                            {...getPrefixedProps(cardWith, 'with')}
                            {...getPrefixedProps(cardWithout, 'without')}
                            {...props}
                        />
                    );
                })}
            </div>
        </div>
    );
}

FeaturedListHeader.propTypes = propTypes;
FeaturedListHeader.defaultProps = defaultProps;

export default FeaturedListHeader;
