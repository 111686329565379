/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Video from '../partials/Video';

import styles from '../../styles/blocks/video-block.module.css';

const propTypes = {
    media: AppPropTypes.video.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function VideoBlock({ media, className }) {
    const { width, height } = media || {};
    return (
        <div
            className={classNames([
                styles.container,

                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                className={styles.shape}
                style={{
                    paddingBottom: `${(height / width) * 100}%`,
                }}
            >
                {media !== null ? <Video media={media} nativeControls className={styles.video} /> : null}
            </div>
        </div>
    );
}

VideoBlock.propTypes = propTypes;
VideoBlock.defaultProps = defaultProps;

export default VideoBlock;
