import { useUser } from '@folklore/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiContext';

export function useSubscribe(defaultSubscription = null) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationKey: ['subscribe', defaultSubscription],
        mutationFn: ({ subscription = defaultSubscription, ...data }) =>
            subscription !== null
                ? api.subscriptions.subscribe(subscription, data)
                : api.subscriptions.subscribe(data),
        onSuccess: (newUser) => {
            queryClient.setQueryData(['user'], newUser);
        },
    });

    return {
        subscribe: mutate,
        subscribeAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useUnsubscribe(defaultSubscription = null) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationKey: ['unsubscribe', defaultSubscription],
        mutationFn: (handle) => api.subscriptions.unsubscribe(handle || defaultSubscription),
        onSuccess: (newUser) => {
            queryClient.setQueryData(['user'], newUser);
        },
    });

    return {
        unsubscribe: mutate,
        unsubscribeAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useSubscribed(subscription) {
    const user = useUser();
    const { subscriptions = [] } = user || {};
    return (
        (subscriptions || []).findIndex(
            ({ id, subscribed = false }) => id === subscription && subscribed,
        ) !== -1
    );
}
