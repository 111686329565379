/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    dotColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    circleColor: 'currentColor',
    dotColor: 'currentColor',
    className: null,
};

function AccountIcon({ color, circleColor, dotColor, className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            className={className}
        >
            <rect width="30" height="30" fill={circleColor} rx="15" />
            <g fill={color}>
                <path
                    d="M21.316 22.939a2.694 2.694 0 1 0 0-5.388 2.694 2.694 0 0 0 0 5.388Z"
                    fill={dotColor}
                />
                <path d="M17.378 20.898H8.56a5.919 5.919 0 0 1 1.714-3.47 5.916 5.916 0 0 1 4.184-1.724c1.572 0 2.99.592 4.102 1.653a4.09 4.09 0 0 1 1.306-.837c-.05-.06-.102-.112-.153-.173a7.43 7.43 0 0 0-2.377-1.592 4.872 4.872 0 0 0 1.98-3.908A4.851 4.851 0 0 0 14.468 6c-2.673 0-4.867 2.173-4.867 4.847 0 1.54.745 3 1.98 3.908a7.268 7.268 0 0 0-2.378 1.592A7.466 7.466 0 0 0 7 21.57a.81.81 0 0 0 .235.582.837.837 0 0 0 .581.245H17.96a3.886 3.886 0 0 1-.581-1.51v.01Zm-6.256-10.051c0-1.847 1.5-3.337 3.337-3.337 1.837 0 3.337 1.5 3.337 3.337 0 1.837-1.5 3.337-3.337 3.337a3.345 3.345 0 0 1-3.337-3.337Z" />
            </g>
        </svg>
    );
}

AccountIcon.propTypes = propTypes;
AccountIcon.defaultProps = defaultProps;

export default AccountIcon;
