/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import intlTelInput from 'intl-tel-input';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import { useDefaultBrand } from '../../contexts/SiteContext';
import TextField from './TextField';

import styles from '../../styles/fields/phone-field.module.css';
import 'intl-tel-input/build/css/intlTelInput.css';

const propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onDropdownOpen: PropTypes.func,
    onDropdownClose: PropTypes.func,
    theme: PropTypes.string,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
    forwardedRef: AppPropTypes.ref,
};

const defaultProps = {
    value: null,
    onChange: null,
    onDropdownOpen: null,
    onDropdownClose: null,
    theme: null,
    inputClassName: null,
    className: null,
    forwardedRef: null,
};

function PhoneField({
    value,
    onChange,
    onDropdownOpen,
    onDropdownClose,
    theme,
    inputClassName,
    className,
    forwardedRef,
    ...props
}) {
    const inputRef = useRef(null);
    const intlRef = useRef(null);

    const { id: brandId = null } = useDefaultBrand();

    const [inputValue, setInputValue] = useState(value);
    const consentGiven = true; // @TODO

    useEffect(() => {
        const { current: input = null } = inputRef;
        if (input === null) {
            return () => {};
        }

        let intl = null;
        if (!consentGiven) {
            intl = intlTelInput(input, {
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.7/build/js/utils.js',
                separateDialCode: true,
                preferredCountries: ['ca', 'fr'],
                // formatAsYouType: true,
                // nationalMode: true,
                initialCountry: brandId === 'france' ? 'fr' : 'ca',
            });
        } else {
            intl = intlTelInput(input, {
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.7/build/js/utils.js',
                separateDialCode: true,
                preferredCountries: ['ca', 'fr'],
                initialCountry: 'auto',
                // formatAsYouType: true,
                // nationalMode: true,
                geoIpLookup: (callback) => {
                    fetch('https://ipapi.co/json')
                        .then((res) => res.json())
                        .then((data) => callback(data.country_code))
                        .catch(() => callback('ca'));
                },
            });
        }

        intlRef.current = intl;

        return () => {
            intl.destroy();
            intlRef.current = null;
        };
    }, [consentGiven]);

    useEffect(() => {
        const { current: input = null } = inputRef;
        if (input === null) {
            return () => {};
        }

        const onCountryDropdownOpen = () => {
            if (onDropdownOpen !== null) {
                onDropdownOpen();
            }
        };

        const onCountryDropdownClose = () => {
            if (onDropdownClose !== null) {
                onDropdownClose();
            }
        };

        const onCountryChange = () => {
            if (onChange !== null && intlRef.current !== null) {
                onChange(intlRef.current.getNumber());
            }
        };

        input.addEventListener('open:countrydropdown', onCountryDropdownOpen);
        input.addEventListener('close:countrydropdown', onCountryDropdownClose);
        input.addEventListener('countrychange', onCountryChange);
        return () => {
            input.removeEventListener('open:countrydropdown', onCountryDropdownOpen);
            input.removeEventListener('close:countrydropdown', onCountryDropdownClose);
            input.removeEventListener('countrychange', onCountryChange);
        };
    }, [onDropdownOpen, onDropdownClose, onChange]);

    const onInputChange = useCallback(
        (e) => {
            const newValue = e.target.value;
            const { current: intl = null } = intlRef;
            const phoneValue = intl !== null ? intl.getNumber() || newValue : newValue;
            setInputValue(newValue);
            if (onChange !== null) {
                onChange(phoneValue);
            }
        },
        [onChange, setInputValue],
    );

    return (
        <div className={classNames([styles.container, className])}>
            <TextField
                {...props}
                type="tel" // Forces phone numerical keyboard, doesn't work for EmailPhoneField
                className={classNames([styles.input, inputClassName])}
                theme={theme}
                onInput={onInputChange}
                value={inputValue}
                withBorder
                ref={(ref) => {
                    inputRef.current = ref;

                    if (forwardedRef !== null && isFunction(forwardedRef)) {
                        // eslint-disable-next-line no-param-reassign
                        forwardedRef(ref);
                    } else if (forwardedRef !== null) {
                        // eslint-disable-next-line no-param-reassign
                        forwardedRef.current = ref;
                    }
                }}
            />
        </div>
    );
}

PhoneField.propTypes = propTypes;
PhoneField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <PhoneField {...props} forwardedRef={ref} />);
