/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import PropTypes from 'prop-types';

// import * as AppPropTypes from '../../../lib/PropTypes';
import TextField from './TextField';

const propTypes = {};

const defaultProps = {};

const EmailField = props => <TextField type="email" {...props} />;

EmailField.propTypes = propTypes;
EmailField.defaultProps = defaultProps;

export default EmailField;
