/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from './Typography';

import styles from '../../styles/typography/page-section-title.module.css';

const propTypes = {
    tag: PropTypes.string,
    brand: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'h4',
    brand: null,
    className: null,
};

function PageSectionTitle({ tag, brand, className, ...props }) {
    return (
        <Typography
            tag={tag}
            className={classNames([
                styles.container,
                {
                    [styles[brand]]: className !== null,
                    [className]: className !== null,
                },
            ])}
            {...props}
        />
    );
}

PageSectionTitle.propTypes = propTypes;
PageSectionTitle.defaultProps = defaultProps;

export default PageSectionTitle;
