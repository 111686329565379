import React from 'react';

export { default as Article } from './ArticleDocument';
export { default as Contest } from './ArticleDocument';
export { default as Video } from './VideoDocument';
export { default as Podcast } from './VideoDocument';
export { default as Interactive } from './InteractiveDocument';
// export { default as Micromag } from './MicromagDocument';
// export const Video = React.lazy(() => import(/* webpackChunkName: "video" */'./VideoDocument'));
export const ArticleLegacy = React.lazy(
    () => import(/* webpackChunkName: "article-legacy" */ './ArticleLegacyDocument'),
);
export const Micromag = React.lazy(
    () => import(/* webpackChunkName: "micromag" */ './MicromagDocument'),
);
