/* eslint-disable react/jsx-props-no-spreading */
import { Editable } from '@niche-js/core/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import getPlaceholderText from '../../lib/getPlaceholderText';

import styles from '../../styles/typography/typography.module.css';

const propTypes = {
    tag: PropTypes.string,
    children: PropTypes.node,
    html: PropTypes.string,
    editable: PropTypes.string,
    editablePicker: PropTypes.string,
    editableInline: PropTypes.bool,
    placeholder: PropTypes.bool,
    placeholderLines: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    tag: 'div',
    children: null,
    html: null,
    editable: null,
    editablePicker: null,
    editableInline: true,
    placeholder: false,
    placeholderLines: 1,
    className: null,
};

function Typography({
    tag: Component,
    children,
    html,
    editable,
    editablePicker,
    editableInline,
    placeholder,
    placeholderLines,
    className,
    ...props
}) {
    const isEditable = editable !== null;
    return isEditable ? (
        <Editable
            tag={Component}
            name={editable}
            className={className}
            html={html}
            picker={editablePicker}
            inline={editableInline}
            {...props}
        >
            {children}
        </Editable>
    ) : (
        <Component
            className={classNames([
                className,
                {
                    [styles.placeholder]: placeholder,
                },
            ])}
            dangerouslySetInnerHTML={html !== null && !placeholder ? { __html: html } : null}
            {...props}
        >
            {placeholder ? getPlaceholderText(placeholderLines) : children}
        </Component>
    );
}

Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export default Typography;
