/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import Image from '../partials/Image';
import CardSubtitle from '../typography/CardSubtitle';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/brand-horizontal-card.module.css';

const propTypes = {
    handle: PropTypes.string,
    slug: PropTypes.string,

    image: AppPropTypes.image,
    images: PropTypes.shape({ logo: AppPropTypes.image, logoBlack: AppPropTypes.image }),
    theme: PropTypes.oneOf([null, 'menu-reverse']),
    width: PropTypes.number,
    ratio: PropTypes.string,
    label: PropTypes.string,
    shortDescription: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    handle: null,
    slug: null,
    image: null,
    images: null,
    theme: null,
    width: 320,
    ratio: null,
    label: null,
    shortDescription: null,
    className: null,
};

function BrandHorizontalCard({
    handle,
    slug,
    image,
    images,
    label,
    shortDescription,
    theme,
    width,
    ratio,
    className,
}) {
    const thumbnailRatio = getRatio(ratio, null);

    const { logoMenu = null } = images || {};

    return (
        <HorizontalCard
            href={slug !== null ? `/${slug}` : '/'}
            height={50}
            thumbnailRatio={theme === 'menu-reverse' ? 12 / 5 : thumbnailRatio}
            thumbnail={
                <Image
                    width={theme === 'menu-reverse' ? 120 : width}
                    height={theme === 'menu-reverse' ? 50 : width / thumbnailRatio}
                    media={theme === 'menu-reverse' ? logoMenu : image}
                    size="small"
                    className={styles.thumbnail}
                    pictureClassName={styles.picture}
                    withoutSize
                />
            }
            className={classNames([
                styles.container,
                styles[handle],
                styles[camelCase(theme)],
                className,
            ])}
            innerClassName={styles.inner}
            labelClassName={styles.label}
        >
            <CardTitle className={styles.name}>{label}</CardTitle>
            <CardSubtitle className={styles.description}>{shortDescription}</CardSubtitle>
        </HorizontalCard>
    );
}

BrandHorizontalCard.propTypes = propTypes;
BrandHorizontalCard.defaultProps = defaultProps;

export default BrandHorizontalCard;
