/* eslint-disable func-names */
import createDebug from 'debug';
import { el } from 'intl-tel-input/i18n';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import set from 'lodash/set';
import EventEmitter from 'wolfy87-eventemitter';

const debug = createDebug('app:tracking:triggers');

class TriggersManager extends EventEmitter {
    constructor(opts) {
        super(opts);

        this.options = {
            ...opts,
        };

        this.dataLayer = null;

        this.triggers = [];
        this.garbageState = {};

        this.documentsViewsCount = 0;

        this.initDataLayer();
    }

    initDataLayer() {
        const { dataLayer = null } = this.options;
        const self = this;
        if (dataLayer !== null) {
            dataLayer.push(function () {
                if (self.dataLayer !== null || this.name !== 'dataLayer') {
                    return;
                }
                self.dataLayer = this;
                self.emit('ready');
                debug('DataLayer ready');
                self.handleEvent('ready');
            });
        }
    }

    register(id, trigger, callback) {
        const { triggers = null, ...triggerObject } = isObject(trigger)
            ? trigger
            : TriggersManager.parseTrigger(trigger);
        const { persist = false } = triggerObject;
        const { [id]: garbageState = null, ...remainingGarbageState } = this.garbageState || {};
        const currentTriggerIndex = this.triggers.findIndex(
            ({ id: triggerId }) => triggerId === id,
        );
        const newTrigger = {
            id,
            trigger: {
                ...triggerObject,
                ...(triggers !== null
                    ? {
                          triggers: triggers.map((subTrigger) =>
                              isObject(subTrigger)
                                  ? subTrigger
                                  : TriggersManager.parseTrigger(subTrigger),
                          ),
                      }
                    : null),
            },
            callback,
            state: (persist ? TriggersManager.pullState(id) || null : null) || garbageState || {},
            timeout: null,
        };
        debug('Register trigger "%s" %O', id, newTrigger);
        if (currentTriggerIndex !== -1) {
            this.triggers[currentTriggerIndex] = newTrigger;
        } else {
            this.triggers.push(newTrigger);
        }
        this.garbageState = remainingGarbageState;
    }

    unregister(id) {
        const currentTrigger = this.triggers.find(({ id: triggerId }) => triggerId === id) || null;
        if (currentTrigger === null) {
            return;
        }
        debug('Unregister trigger "%s"', id);
        const { timeout = null, state = null } = currentTrigger;
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        this.garbageState[id] = state;
        this.triggers = this.triggers.filter(({ id: triggerId }) => triggerId !== id);
    }

    push(...args) {
        const events = args
            .filter((arg) => isObject(arg) && typeof arg.event !== 'undefined')
            .map((arg) => {
                const eventName = arg.event.toLowerCase();
                if (eventName === 'eventinteraction') {
                    const { eventCategory, eventAction } = arg;
                    return `${eventCategory.toLowerCase()}_${eventAction.toLowerCase()}`;
                }
                return eventName;
            });
        if (this.dataLayer === null) {
            this.once('ready', () => {
                events.forEach((event) => this.handleEvent(event));
            });
        } else {
            events.forEach((event) => this.handleEvent(event));
        }
    }

    handleEvent(event) {
        const eventObject = {
            name: event,
            page: {
                title: this.dataLayer.get('pageTitle'),
                type: this.dataLayer.get('pageType'),
                brands: this.dataLayer.get('brands'),
                categories: this.dataLayer.get('categories'),
                tags: this.dataLayer.get('tags'),
                authors: this.dataLayer.get('authors'),
                identifiers: this.dataLayer.get('identifiers'),
            },
            authenticated: (this.dataLayer.get('user') || null) !== null,
            consentGiven: this.dataLayer.get('consentGiven'),
            scrollPercent: this.dataLayer.get('scrollPercent'),
            documentProgress: this.dataLayer.get('documentProgress'),
        };

        debug('Handle event "%s"', event);

        // Compute triggers states
        const newTriggers = this.triggers.reduce((currentTriggers, triggerDef, index) => {
            const { id: triggerId, state: currentState, trigger } = triggerDef;
            const { matched: currentMatched = false } = currentState || {};

            if (!TriggersManager.matchTrigger(trigger, eventObject) || currentMatched === 'once') {
                return currentTriggers;
            }
            const { persist = false } = trigger || {};

            debug('Match trigger "%s"', triggerId);
            const newState = TriggersManager.computeState(triggerDef, eventObject);

            if (currentState === newState) {
                return currentTriggers;
            }

            debug('Update state for Trigger "%s" update state %o', triggerId, newState);

            if (persist) {
                debug('Trigger "%s" persist state', triggerId);
                TriggersManager.persistState(triggerId, newState);
            }

            const newTrigger = { ...triggerDef, state: newState };
            return [
                ...currentTriggers.slice(0, index),
                newTrigger,
                ...currentTriggers.slice(index + 1),
            ];
        }, this.triggers);

        // Calling triggers
        this.triggers = newTriggers.reduce((currentTriggers, trigger, index) => {
            const {
                id,
                callback,
                trigger: { delay = null, persist = false } = {},
                state,
                timeout = null,
            } = trigger;
            const { matched = false, called = false } = state || {};
            if (called || timeout !== null || matched === false) {
                return currentTriggers;
            }
            let newTrigger;
            if (delay !== null) {
                debug('Calling trigger "%s" in %d ms...', id, delay);
                const newTimeout = setTimeout(() => {
                    debug('Calling trigger "%s"...', id);
                    callback();
                    this.triggers = this.triggers.map((subTrigger) => {
                        if (subTrigger.id === id) {
                            const {
                                trigger: { persist: subPersist = false } = {},
                                state: subState,
                            } = subTrigger || {};
                            const shouldReset = TriggersManager.triggerShouldReset(subTrigger);
                            const newState = shouldReset
                                ? {}
                                : {
                                      called: matched === 'once',
                                      ...subState,
                                  };
                            if (shouldReset && subPersist) {
                                TriggersManager.persistState(id, newState);
                            }
                            return {
                                ...subTrigger,
                                timeout: null,
                                state: newState,
                            };
                        }
                        return subTrigger;
                    });
                }, delay);
                newTrigger = {
                    ...trigger,
                    timeout: newTimeout,
                };
            } else {
                debug('Calling trigger "%s"...', id);
                callback();
                const shouldReset = TriggersManager.triggerShouldReset(trigger);
                const newState = shouldReset
                    ? {}
                    : {
                          ...state,
                          called: matched === 'once',
                      };
                if (persist) {
                    TriggersManager.persistState(id, newState);
                }
                newTrigger = {
                    ...trigger,
                    timeout: null,
                    state: newState,
                };
            }

            const { timeout: newTimeout = null, state: newState } = newTrigger;
            const { called: newCalled = false } = newState || {};

            return newCalled !== called || newTimeout !== timeout
                ? [
                      ...currentTriggers.slice(0, index),
                      newTrigger,
                      ...currentTriggers.slice(index + 1),
                  ]
                : currentTriggers;
        }, newTriggers);
    }

    static triggerShouldReset(trigger) {
        const { trigger: { reset = null } = {}, state } = trigger;
        const { matched = false } = state || {};
        return (reset === null && matched === true) || reset === true;
    }

    static matchTrigger(trigger, event) {
        return [
            // Match event name
            ({ event: triggerEvent = null }, { name: eventName }) =>
                triggerEvent === eventName ||
                ['sequence', 'all', 'one'].indexOf(triggerEvent) !== -1,

            // Match page
            ({ pageMatch = null }, { page }) =>
                pageMatch === null || TriggersManager.matchPage(page, pageMatch),

            // Match consent
            ({ consentGiven: triggerConsentGiven }, { consentGiven = null }) =>
                typeof triggerConsentGiven === 'undefined' || triggerConsentGiven === consentGiven,

            // Match authenticated
            ({ authenticated: triggerAuthenticated = false }, { authenticated = false }) =>
                triggerAuthenticated === authenticated,

            // Match scroll percent
            ({ scrollPercent: triggerScrollPercent = null }, { scrollPercent = null }) =>
                triggerScrollPercent === null ||
                (scrollPercent !== null && scrollPercent >= triggerScrollPercent),

            // Match scroll percent
            ({ documentProgress: triggerDocumentProgress = null }, { documentProgress = null }) =>
                triggerDocumentProgress === null ||
                (documentProgress !== null && documentProgress >= triggerDocumentProgress),

            // Match triggers
            ({ triggers = null }, subEvent) =>
                triggers === null ||
                triggers.reduce(
                    (match, subTrigger) =>
                        TriggersManager.matchTrigger(subTrigger, subEvent) || match,
                    false,
                ),
        ].reduce((allMatch, matcher) => allMatch && matcher(trigger, event), true);
    }

    static matchPage(page, pageMatch) {
        const {
            brands = null,
            brand = null,
            keywords = null,
            types = null,
            type = null,
        } = pageMatch || {};
        const finalBrands = [
            ...TriggersManager.normalizeKeywords(brands),
            ...TriggersManager.normalizeKeywords(brand),
        ];
        const finalKeywords = TriggersManager.normalizeKeywords(keywords);
        const finalTypes = [
            ...TriggersManager.normalizeKeywords(types),
            ...TriggersManager.normalizeKeywords(type),
        ];
        const {
            type: pageType,
            brands: pageBrands = null,
            categories: pageCategories = null,
            keywords: pageKeywords = null,
            authors: pageAuthors = null,
            tags: pageTags = null,
        } = page || {};

        const allKeywords = TriggersManager.normalizeKeywords([
            ...(pageBrands || []),
            ...(pageCategories || []),
            ...(pageKeywords || []),
            ...(pageAuthors || []),
            ...(pageTags || []),
        ]);
        const matchBrands =
            finalBrands === null ||
            finalBrands.length === 0 ||
            (pageBrands || []).findIndex((it) => finalBrands.indexOf(it) !== -1) !== -1;
        const matchKeywords =
            finalKeywords === null ||
            finalKeywords.length === 0 ||
            (allKeywords || []).findIndex((it) => finalKeywords.indexOf(it) !== -1) !== -1;
        const matchType =
            finalTypes === null || finalTypes.length === 0 || finalTypes.indexOf(pageType) !== -1;
        return matchBrands && matchKeywords && matchType;
    }

    static normalizeKeywords(keywords) {
        return ((keywords !== null && isString(keywords) ? [keywords] : keywords) || [])
            .map((it) => (isString(it) ? TriggersManager.sanitizeKeyword(it) : null))
            .filter((it) => !isEmpty(it));
    }

    static sanitizeKeyword(keyword) {
        return isString(keyword) ? (keyword || '').toLowerCase().replace(/[^a-z0-9]+/gi, '') : null;
    }

    static computeState(triggerDef, event) {
        const { state, trigger } = triggerDef;
        const { event: triggerEvent, triggers = null, once = true } = trigger || {};

        if (triggers !== null) {
            const { states: currentTriggerStates = [], matched: currentMatched = false } =
                state || {};
            const { states: newTriggerStates } = triggers.reduce(
                ({ states: triggerStates, previousMatched = false }, subTrigger, index) => {
                    // Skip this sub trigger
                    if (
                        (triggerEvent === 'sequence' && previousMatched === false) ||
                        !TriggersManager.matchTrigger(subTrigger, event)
                    ) {
                        const { matched = false } = triggerStates[index] || {};
                        return {
                            states:
                                triggerStates.length <= index
                                    ? [...triggerStates, { matched: false }]
                                    : triggerStates,
                            previousMatched:
                                previousMatched === null || previousMatched !== false
                                    ? matched
                                    : previousMatched,
                        };
                    }
                    const subTriggerState = triggerStates[index] || null;
                    const newState = TriggersManager.computeTriggerState(
                        subTrigger,
                        subTriggerState,
                    );
                    return {
                        states:
                            newState !== subTriggerState
                                ? [...triggerStates.slice(0, index), newState]
                                : triggerStates,
                        previousMatched: newState.matched !== false,
                    };
                },
                {
                    states: currentTriggerStates,
                    previousMatched: null,
                },
            );
            const allMatched = newTriggerStates.reduce((allMatch, { matched = false }) => {
                if (allMatch === null) {
                    return matched !== false;
                }
                return (
                    (allMatch && matched !== false) ||
                    (triggerEvent === 'one' && (allMatch || matched !== false))
                );
            }, null);
            const newMatched = allMatched && once ? 'once' : allMatched;
            const stateChanged =
                newMatched !== currentMatched || newTriggerStates !== currentTriggerStates;
            return stateChanged
                ? {
                      ...state,
                      states: newTriggerStates,
                      matched: newMatched,
                  }
                : state;
        }

        return TriggersManager.computeTriggerState(trigger, state);
    }

    static pullState(id) {
        const state = Cookies.get(`trigger_state_${id}`) || null;
        return state !== null ? TriggersManager.parseObject(state) : null;
    }

    static persistState(id, state) {
        const serializedState = TriggersManager.serializeObject(state);
        Cookies.set(`trigger_state_${id}`, serializedState, {
            expires: 30,
        });
    }

    static computeTriggerState(trigger, state) {
        const { count: triggerCount = 1, once = true } = trigger || {};
        const { matched: currentMatched = false, count: currentCount = 0 } = state || {};
        const newCount = currentCount + 1;
        const countMatch = newCount >= triggerCount;
        const triggerMatched = countMatch;
        const newMatched = triggerMatched && once ? 'once' : countMatch;
        const stateChanged = newMatched !== currentMatched || newCount !== currentCount;
        return stateChanged
            ? {
                  ...state,
                  matched: newMatched,
                  count: newCount,
              }
            : state;
    }

    static serializeObject(trigger, prefix = null) {
        let triggerObject = trigger;
        if (isString(trigger)) {
            triggerObject = TriggersManager.parseObject(trigger);
        }
        const objectMap = isArray(triggerObject)
            ? triggerObject.map((value, key) => [key, value])
            : Object.keys(triggerObject).map((key) => [key, triggerObject[key]]);
        return objectMap
            .map(([key, value]) => {
                const keyWithPrefix = prefix !== null ? `${prefix}.${key}` : key;
                if (isObject(value)) {
                    return TriggersManager.serializeObject(value, keyWithPrefix);
                }
                if (isArray(value) && value.length > 0 && isObject(value[0])) {
                    return TriggersManager.serializeObject(value, keyWithPrefix);
                }
                return value === true
                    ? keyWithPrefix
                    : `${keyWithPrefix}:${isArray(value) ? value.join(',') : value}`;
            })
            .join('|');
    }

    static parseTrigger(trigger) {
        const [event, parameters = null] = trigger.split('|', 2);
        return {
            event,
            ...TriggersManager.parseObject(parameters),
        };
    }

    static parseObject(trigger) {
        return (trigger || '').split('|').reduce((acc, part) => {
            if (part.length === 0) {
                return acc;
            }
            const [key, value = null] = part.split(':', 2);
            let finalValue = value !== null && value.indexOf(',') !== -1 ? value.split(',') : value;
            if (finalValue === 'false') {
                finalValue = false;
            } else if (finalValue === 'true') {
                finalValue = true;
            } else if (finalValue === 'null') {
                finalValue = null;
            } else if (finalValue !== null && finalValue.match(/^[0-9]+$/) !== null) {
                finalValue = parseInt(finalValue, 10);
            } else if (finalValue !== null && finalValue.match(/^[0-9.]+$/) !== null) {
                finalValue = parseFloat(finalValue);
            }
            set(acc, key, finalValue !== null ? finalValue : true);
            return acc;
        }, {});
    }
}

export default TriggersManager;
