/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import { useIsEditor } from '@niche-js/core/editor/contexts';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Author from '../partials/Author';
import Category from '../partials/Category';
import Date from '../partials/Date';
import Image from '../partials/Image';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import DocumentMetadata from '../typography/DocumentMetadata';
import DocumentSubtitle from '../typography/DocumentSubtitle';
import DocumentTitle from '../typography/DocumentTitle';

import styles from '../../styles/headers/document-over-header.module.css';

const propTypes = {
    type: PropTypes.string,
    sponsors: AppPropTypes.sponsors,
    brands: AppPropTypes.brands,
    title: PropTypes.string,
    surtitle: PropTypes.string,
    subtitle: PropTypes.string,
    date: PropTypes.string,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    color: PropTypes.string,
    placeholder: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    type: null,
    brands: null,
    title: null,
    surtitle: null,
    subtitle: null,
    date: null,
    image: null,
    coverImage: null,
    categories: null,
    credits: null,
    sponsors: null,
    color: null,
    placeholder: false,
    className: null,
};

function DocumentOverHeader({
    title,
    surtitle,
    subtitle,
    date,
    brands,
    image,
    categories,
    credits,
    sponsors,
    color,
    placeholder,
    className,
}) {
    const isEditor = useIsEditor();
    const url = useUrlGenerator();
    const [category = null] = categories || [];
    const [{ author = null } = {}] = credits || [];
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const [sponsor = null] = sponsors || [];
    const { isPerson: authorIsPerson = false } = author || {};

    const withBefore = surtitle !== null || isEditor;

    const withAfter = author !== null || date !== null || subtitle !== null || isEditor;
    const hasAuthor = (author !== null && authorIsPerson) || isEditor;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [className]: className !== null,
                },
            ])}
            style={{
                color: color !== null ? color : null,
            }}
        >
            <Image className={styles.image} media={image} size="responsive" />

            <div className={styles.inner}>
                <div className={styles.top}>
                    {category !== null ? (
                        <Category
                            {...category}
                            placeholder={placeholder}
                            className={styles.category}
                        />
                    ) : null}
                </div>

                <div className={styles.middle}>
                    {withBefore ? (
                        <div className={styles.before}>
                            {surtitle !== null ? (
                                <Surtitle
                                    surtitle={surtitle}
                                    withLink
                                    className={styles.surtitle}
                                />
                            ) : null}
                        </div>
                    ) : null}

                    <DocumentTitle className={styles.title}>{title}</DocumentTitle>

                    {withAfter !== null ? (
                        <div className={styles.after}>
                            {subtitle !== null || isEditor ? (
                                <DocumentSubtitle className={styles.subtitle} editable="subtitle">
                                    {subtitle}
                                </DocumentSubtitle>
                            ) : null}

                            {hasAuthor ? (
                                <Author
                                    {...author}
                                    className={styles.author}
                                    nameClassName={styles.authorName}
                                    withRole
                                />
                            ) : null}

                            {date !== null || isEditor ? (
                                <DocumentMetadata
                                    className={classNames([
                                        styles.date,
                                        {
                                            [styles.hasAuthor]: hasAuthor,
                                        },
                                    ])}
                                >
                                    {date != null ? <Date date={date} /> : 'Date'}
                                </DocumentMetadata>
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <div className={styles.bottom}>
                    {sponsor !== null || isEditor ? (
                        <Sponsor
                            {...sponsor}
                            withImage
                            className={styles.sponsor}
                            imageClassName={styles.sponsorImage}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

DocumentOverHeader.propTypes = propTypes;
DocumentOverHeader.defaultProps = defaultProps;

export default DocumentOverHeader;
