/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import * as AppPropTypes from '../../lib/PropTypes';
import { createHomeSectionGrid, createLayoutRow, createVideosRow } from '../../lib/blocks';

import PageSectionTitle from '../typography/PageSectionTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-mollo.module.css';
import { usePopularDocuments } from '../../hooks/useDocuments';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageMollo({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const { sections = [] } = brand || {};
    const blocks = useUniqueDocumentsBlocks([
        {
            role: 'block',
            type: 'header_featured_sidebar',
            brand,
            withBrandLogo: false,
            // withShadow: true,
            cardTheme: 'box-translucent',
            responsive: true,
            count: 7,
            className: styles.featuredBlock,
            query: {
                brand: brand.id,
            },
            withShadow: true,
            uniqueDocuments: true,
        },

        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <PageSectionTitle brand={brand.handle} className={styles.sectionTitle}>
                    Les plus populaires
                </PageSectionTitle>
            ),
            query: {
                brand: brand.id,
                // popular: 'month',
            },
            hook: usePopularDocuments,
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        createVideosRow({
            title: (
                <PageSectionTitle brand={brand.handle} className={styles.sectionTitle}>
                    <Link href={url('videos', { brand: brand.slug })}>Vidéos récentes</Link>
                </PageSectionTitle>
            ),
            query: {
                brand: brand.id,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            uniqueDocuments: true,
        }),



        ...['divertissement', 'sante']
            .map((slug) => sections.find(({ slug: sectionSlug }) => sectionSlug === slug) || null)
            .filter((it) => it !== null)
            .map(({ id: sectionId, label, slug }) =>
                createHomeSectionGrid({
                    title: (
                        <PageSectionTitle brand={brand.handle} className={styles.sectionTitle}>
                            <Link href={url('section', { brand: brand.slug, section: slug })}>
                                {label}
                            </Link>
                        </PageSectionTitle>
                    ),
                    query: {
                        brand: brand.id,
                        section: sectionId,
                    },
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardTheme: 'rounded',
                    cardWithout: ['category'],
                }),
            ),
    ]);
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            blocks={blocks}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageMollo.propTypes = propTypes;
HomePageMollo.defaultProps = defaultProps;

export default HomePageMollo;
