/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';
import { getTrackingQuery } from '../../lib/utils';

import Summary from './Summary';

import styles from '../../styles/summaries/author-summary.module.css';

const propTypes = {
    slug: PropTypes.string,
    clickRef: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
};

const defaultProps = {
    slug: null,
    clickRef: null,
    name: null,
    title: null,
    description: null,
    shortDescription: null,
    className: null,
    imageClassName: null,
    pictureClassName: null,
};

function AuthorSummary({
    slug,
    clickRef,
    name,
    title,
    description,
    shortDescription,
    className,
    imageClassName,
    pictureClassName,
    ...props
}) {
    const url = useAuthorUrlGenerator();
    return (
        <Summary
            link={url(
                {
                    slug,
                },
                getTrackingQuery({
                    clickRef: clickRef || 'summary',
                }),
            )}
            title={name}
            description={shortDescription || description}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            imageClassName={classNames([
                styles.image,
                {
                    [imageClassName]: imageClassName !== null,
                },
            ])}
            pictureClassName={classNames([
                styles.picture,
                {
                    [pictureClassName]: pictureClassName !== null,
                },
            ])}
            buttonLabel={<FormattedMessage defaultMessage="Voir plus" description="Button label" />}
            withButton
            {...props}
        />
    );
}

AuthorSummary.propTypes = propTypes;
AuthorSummary.defaultProps = defaultProps;

export default AuthorSummary;
