/* eslint-disable react/jsx-props-no-spreading */
import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense, useMemo } from 'react';

import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import { getComponentFromName } from '@folklore/utils';

import ItemsLoader from './ItemsLoader';
import * as ListPresentations from './presentations';
import { useIsIdle } from '../../contexts/IdleContext';

const propTypes = {
    hook: PropTypes.func.isRequired,
    type: PropTypes.string,
    title: PropTypes.node,
    count: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    query: PropTypes.object,
    disabled: PropTypes.bool,
    // keepPreviousData: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    initialData: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    loading: PropTypes.oneOf([null, 'lazy']),
    presentation: PropTypes.oneOf(['grid', 'row', 'layout', 'inline']),
    // eslint-disable-next-line react/forbid-prop-types
    paginated: PropTypes.bool,
    placeholderItem: PropTypes.object,
    placeholderCount: PropTypes.number,
    withoutLoader: PropTypes.bool,
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    gridClassName: PropTypes.string,
    onLoaded: PropTypes.func,
};

const defaultProps = {
    type: null,
    title: null,
    count: null,
    query: null,
    disabled: false,
    // keepPreviousData: false,
    initialData: null,
    items: null,
    loading: null,
    presentation: 'grid',
    paginated: true,
    placeholderItem: null,
    placeholderCount: null,
    withoutLoader: false,
    className: null,
    rowClassName: null,
    gridClassName: null,
    onLoaded: null,
};

function PresentationList({
    hook,
    presentation,
    count,
    query,
    disabled,
    // keepPreviousData,
    initialData,
    items,
    loading,
    paginated,
    placeholderItem,
    placeholderCount,
    withoutLoader,
    className,
    rowClassName,
    gridClassName,
    onLoaded,
    ...props
}) {
    const { ref, visible } = useIsVisible({
        persist: true,
        disabled: loading !== 'lazy',
    });

    const placeholderItems = usePlaceholderItems(placeholderCount || count || 3, placeholderItem);
    const finalQuery = useMemo(
        () => ({
            count,
            ...query,
        }),
        [count, query],
    );

    const isIdle = useIsIdle();

    const finalDisabled = disabled || (loading === 'lazy' && !visible) || isIdle;

    const ListComponent = getComponentFromName(ListPresentations, presentation, 'grid');
    const list = (
        <ListComponent
            {...props}
            ref={ref}
            className={classNames([
                {
                    [className]: className !== null,
                    [gridClassName]: gridClassName !== null && presentation === 'grid',
                    [rowClassName]: rowClassName !== null && presentation === 'row',
                },
            ])}
        />
    );

    return withoutLoader ? (
        React.cloneElement(list, {
            items: items || placeholderItems,
        })
    ) : (
        // <Suspense
        //     fallback={React.cloneElement(list, {
        //         items: placeholderItems,
        //     })}
        // >
        <ItemsLoader
            hook={hook}
            query={finalQuery}
            suspense={false}
            disabled={finalDisabled}
            // keepPreviousData={keepPreviousData}
            initialData={initialData}
            placeholderData={
                paginated
                    ? {
                          data: placeholderItems,
                      }
                    : placeholderItems
            }
            onLoaded={onLoaded}
        >
            {list}
        </ItemsLoader>
        // </Suspense>
    );
}

PresentationList.propTypes = propTypes;
PresentationList.defaultProps = defaultProps;

export default PresentationList;
